import React, { useState, useEffect } from 'react';
import Button from '../button/button';
import styles from "./style.module.scss";

import { IMG_URL, COOKIE_BOOKMARKS, COOKIE_DURATION_DAYS, BASENAME} from "../../constant"
import { toPrice, getCookieForBookmark, setCookieForBookmark, discountedPrice, endPrice } from "../../helper/functions";

export default function CarPreview(props) {
    const [bookmarked, setBookmarked] = useState(props.bookmarkFlag);

    function changeBookmarkValue(cookieFlag) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+props.data.VclId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === null)
        {
            cookieFlag = true
        }
        // console.log(`WRITE ${props.data.VclId} → TO ${cookieFlag} FROM ${cookieValue}`);
        // salva true o false nel cookie (es. nome → milAutoCookieBook14980 )
        setCookieForBookmark(COOKIE_BOOKMARKS+"_"+props.data.VclId, cookieFlag, COOKIE_DURATION_DAYS);        
    }

    useEffect(() => {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+props.data.VclId);
        // ritorna true o false in base al valore (null → false)
        let cookieFlag
        if (cookieValue === null)
        {
            cookieFlag = false
        } else {
            cookieFlag = cookieValue
        }

        if (props.bookmarkFlag) {
            setBookmarked(props.bookmarkFlag)
        } else {
            setBookmarked(cookieFlag)
        }

        //var cookieFlag = cookieValue ? false : true
        //console.log(`WRITE ${props.data.VclId} → TO ${cookieFlag} FROM ${cookieValue}`);
        // salva true o false nel cookie (es. nome → milAutoCookieBook14980 )
        setCookieForBookmark(COOKIE_BOOKMARKS+"_"+props.data.VclId, cookieFlag, COOKIE_DURATION_DAYS);
    }, [bookmarked, props.data.VclId])

    function checklenght(valueToCheck) {
        if (valueToCheck > 3)
            return true;
        return false;
    }

    const renderAttribute = (title, value, icon, size) => {
        if (!value) { value="" }
        
        if ((title === "Chilometraggio") && (value !== "") )
        {
            const isDotted = checklenght(value.length);
            var kmToArray = [];
            if (isDotted)
            {
                const splitIndex = value.length -3;
                kmToArray = value.split("");

                kmToArray.splice(splitIndex, 0, ".");
                value = kmToArray.join('');
            }
        }

        return (
            <div className="d-flex align-items-start" key={title + value} style={{flex:1}}>
                <img alt={title} src={"../site_images/svg/" + icon} width={size} height={size} /> &ensp;
                <strong style={{ color: '#002A59', fontSize:15 }}>{capitalize(value)}</strong>
            </div>
        )
    }

    function capitalize (str) {
        return str && str[0].toUpperCase() + str.slice(1);
    }

    function renderTag(el, index) {
        if (!el.Posizione || !el.Link) {
            return null;
        }

        return (
            <div className={styles.tag} key={"tag-" + index}>
                <img src={"../" + el.Link} alt={el.Descrizione}></img>
            </div>
        );
    }

    return (
        <div className={styles.mainContainer} onClick={() => {
            if (props.insideCarousel) {
                if (!props.isMoving) {
                    window.location.href = window.location.origin +BASENAME + "veicolo/" + props.data.VclId
                }
            } else {
                window.location.href = window.location.origin + BASENAME+ "veicolo/" + props.data.VclId
            }
        }}>
            <div className={styles.bookmarkContainer}>
                <Button icon={["far","heart"]}
                    variation={ bookmarked ? "bookmarkSelected" : "bookmark"}
                    onClick={(e) => {
                        //console.log(e)
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation();

                        //console.log(`Bookmark BEFORE → ${bookmarked}`);
                        let newBookmarkedValue = !bookmarked
                        setBookmarked(newBookmarkedValue);
                        //console.log(`Bookmark AFTER → ${newBookmarkedValue}`);
                        changeBookmarkValue(newBookmarkedValue)
                        //console.log(`Bookmark AFTER CHANGE COOKIE → ${newBookmarkedValue}`);
                    }}
                    style={{ height: "auto", width: '40px'}}
                />
            </div>
            <div className={styles.imageContainer}>
                <img src={IMG_URL + props.data.img} className={styles.image} />
                <div className={styles.imageOverlay}>
                    <div className={styles.tagTopLeft}>{props.data.tag.map((el, index) => { if (el.Posizione =="SxUp")return renderTag(el, index) })}</div>
                    <div className={styles.tagTopRight}>{props.data.tag.map((el, index) => { if (el.Posizione == "DxUp") return renderTag(el, index) })}</div>
                    <div className={styles.tagBottomLeft}>{props.data.tag.map((el, index) => { if (el.Posizione == "SxDown") return renderTag(el, index) })}</div>
                    <div className={styles.tagBottomRight}>{props.data.tag.map((el, index) => { if (el.Posizione == "DxDown") return renderTag(el, index) })}</div>
                    
                </div>
            </div>
            <div className={styles.contentContainer} >
                <div className={styles.carTopcontainer}>
                    <div className={styles.carTitle}><span className="notranslate">{props.data.Marca + " " + props.data.Modello}</span> </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end", flex:1}}>
                        <div className={styles.price}>{toPrice(endPrice(props.data))}</div>
                    </div>
                </div>

                {(props.data.PrezzoScontato) ?
                    <div style={{display:"flex", alignItems:'flex-start'}}>
                        <div className={styles.carMiniTitle}>
                            {props.data.DescrizioneSynth}
                        </div>
                        <div className={styles.discountetPrice}>{toPrice(props.data.PrezzoFinale)}</div>
                    </div>
                    : 
                    <div className={styles.carMiniTitle}>
                        {props.data.DescrizioneSynth}
                    </div>

                }
                {props.data.Listino ?
                    <div className={styles.carMiniTitle} style={{fontWeight:650, marginTop:4} }>
                        (prezzo a nuovo {toPrice(props.data.Listino)})
                    </div>
                    : null
                }
                <br/>
                <div className="d-flex align-items-center justify-content-between" style={{marginBottom: -8}}>
                    {renderAttribute("Chilometraggio", props.data.Percorrenza, "Icon_card_km.svg", 20)}
                    {renderAttribute("Immatricolazione", props.data.Immatricolazione, "Icon_card_calendar.svg", 20)}
                </div>
                <br />
                <div className="d-flex align-items-start justify-content-between" style={{marginBottom: -8}}>
                    {renderAttribute("Alimentazione", props.data.Alimentazione, "Icon_card_fuel.svg", 20)} 
                    {renderAttribute("Cambio", props.data.Cambio, "Icon_card_cambio.svg", 20)}
                </div>
            </div>
        </div>
    );
}

