import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import styles from "./style.module.scss";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/button/button';

export const FilterMobile = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const removeFilters = () => {
        console.log("remove")
    }

    ref.current = handleShow;



    return (
        <Offcanvas show={show} onHide={handleClose} style={{ backgroundColor: "#E6EAF1" }}>
            <Offcanvas.Header>
                <div className={styles.filterMobileHeaderContainer}>
                    <img src="./site_images/logo_milanoauto.png" />
                    <div className={styles.filterMobileHeaderIconContainer}>
                        <img src="./site_images/icon/icon-close.png" onClick={() => handleClose()}/>
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <div className={styles.filterMobileTop}>
                    <div className={styles.filterMobileTopLabel}>
                        Filtra ricerca
                    </div>
                    <div className={styles.filterMobileTopRemove}>
                        <button onClick={() => removeFilters()}>Rimuovi filtri</button>
                    </div>
                </div>
                {props.renderFilters()}
                <Button label={"Applica filtri"} style={{ width: "100%", marginTop: 25 }} variation={"tertiary"} onClick={()=>handleClose()}/>
            </Offcanvas.Body>
        </Offcanvas>
    );
})

