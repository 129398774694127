import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';
import { translate, translateValue } from '../../helper/functions';

export default function About() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle} dangerouslySetInnerHTML={{ __html: translate("ChiSiamoTitolo") }}/>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("ChiSiamoPath01"), link: BASENAME },
                        { label: translate("ChiSiamoPath02"), link: null }
                    ]}

                />
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                            <h2 className="mb-1">{translate("ChiSiamo01Testo01")}</h2>
                            <p dangerouslySetInnerHTML={{__html:translate("ChiSiamo01Testo02") }}/>
                            <img className="w-100" src={translateValue("ChiSiamo01Img01")} />
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="row flex-lg-wrap-reverse flex-wrap">
                                    <div className="col-12 mt-5">
                                    <p dangerouslySetInnerHTML={{ __html: translate("ChiSiamo02Testo01") }} />
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6">
                                            <img src={translateValue("ChiSiamo02Img01")} className="w-100" style={{ borderRadius: '40px' }} />
                                            </div>
                                            <div className="col-6">
                                            <img src={translateValue("ChiSiamo02Img02")} className="w-100" style={{ borderRadius: '40px' }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <h2 className="text-center mb-5" dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo01") }}></h2>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                            <h3 dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo02") }} />
                            <p dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo03") }}/>
                            </div>

                            <div className="col-12 col-lg-4">
                            <h3 dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo04") }} />
                            <p dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo05") }} />
                            <h3 dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo06") }} />
                            <p dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo07") }} />
                            </div>

                            <div className="col-12 col-lg-4" style={isMobile ? { marginBottom: -33 } : null}>
                            <h3 dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo08") }} />
                            <p dangerouslySetInnerHTML={{ __html: translate("ChiSiamo03Testo09") }} />
                            </div>
                        </div>
                </div>
            </div>
        </Layout>
    );
}

