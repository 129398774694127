import React from 'react';
import Layout from '../../components/layout/layout';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";


export default function ThankYou() {


    return (
        <Layout
            removeContactForm={true}
        >
            <div className={styles.externalContainer}>
                <div className={styles.card}>
                    <h2 className={styles.title}>Il tuo messaggio è stato inviato con successo</h2>
                    <img src="site_images/mail-contact.png" className={styles.icon}></img>
                    <p className={styles.text}>Grazie per aver inviato il tuo messaggio.
                        Il nostro Team ti risponderà il prima possibile.
                        Puoi continuare la navigazione o tornare alla nostra <a href="../" className={styles.link}>Homepage</a>
                        .
                    </p>
                    <img src="site_images/logo-contact.png" className={styles.logo}></img>
                </div>
            </div>

        </Layout>
    );
}

