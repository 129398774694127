import React, { useState, useEffect } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";

import Button from "../../components/button/button";
import Rating from "../../components/rating/rating";
import CarPreview from "../../components/carPreview/carPreview";
import MyCarousel from '../../components/carousel/carousel';
import Carousel from 'react-multi-carousel';

import {ajaxGet, ajaxPost} from "../../helper/ajax";
import { useNavigate } from "react-router-dom";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate, translateValue } from '../../helper/functions';

export default function Home() {
    const navigate = useNavigate();

    const [carList, setCarList] = useState([]);
    const [porscheList, setporscheList] = useState([]);
    const [filterList, setFilterList] = useState({});

    const [carType, setCarType] = useState("usato");
    const [carBrand, setCarBrand] = useState(null);
    const [carModel, setCarModel] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [search, setSearch] = useState("");

    useEffect(() => {
        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                setCarList(filterFeatured(response.message))
            }
        });

        let formdata = new FormData();
        formdata.append("brand", "PORSCHE");
        ajaxPost("getVclList.php", formdata).then((response) => {
            if (response.code == "100") {
                setporscheList(response.message.car)
            }
        });

        ajaxGet("getFilters.php").then((response) => {
            if (response.code == "100") {
                setFilterList(response.message);
            }
        });

    }, [])

    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items:1

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const redirectToSearch = (val) => {
        navigate('veicoli', {
            state: {
                search: val
            }
        })
    }

    const filterFeatured = arr => {
        arr.sort((a, b) => (new Date(a.StartData) < new Date(b.StartData)) ? 1 : (new Date(a.StartData) > new Date(b.StartData)) ? -1 : 0)
        let temp = [];
        arr.map((el) => {
            if (temp.length >= 9) { return }

            if (el.ClasseComm == "usato") {
                if (el.Immatricolazione) {
                    let year = el.Immatricolazione.substr(el.Immatricolazione.length - 4);
                    let currentYear = new Date().getFullYear();
                    if (currentYear - year <= 2) {
                        temp.push(el)
                    }
                }
            } else {
                temp.push(el)
            }
        })
        return temp;
    }

    return (
        <Layout>

            <div className="d-sm-block d-md-none">
                <div className={styles.homeSearchContainer}>
                    <div className={styles.inputWithIcon}>
                        <input
                            placeholder="Cerca la tua auto"
                            onBlur={(val) => setSearch(val.target.value)}
                            onKeyUp={(val) => { if (val.key == "Enter") { redirectToSearch(val.target.value) } }}
                        />
                        <img src="site_images/icon/icon-lense.png" onClick={() => redirectToSearch(search)}/>
                    </div>
                </div>
            </div>

            <Carousel
                responsive={responsive}
                arrows={isMobile ? false : true}
                showDots={true}
                containerClass={"HomeCarousel"}
                autoPlay={true}
                autoPlaySpeed={5000}
                infinite={true}
            >
                {translateValue("HomeCaroselloImg").split("|").map((el) => {
                    return <img src={el} className={styles.homeSlider} />
                })}
            </Carousel>

            <section className='sections_home' style={{ marginBottom: 0 }}>
                <div className="container">
                    <div className={styles.searchContainer}>
                        <div className={styles.searchButtonContainer}>

                            <input type="radio" className="btn-check" value="semestrale" name="carType" id="option3" defaultChecked onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option3">{translate("HomeSelezioneTasto01")}</label>

                            <input type="radio" className="btn-check" value="km 0" name="carType" id="option2" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option2">{translate("HomeSelezioneTasto02")}</label>

                            <input type="radio" className="btn-check" value="usato" name="carType" id="option4" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option4">{translate("HomeSelezioneTasto03")}</label>

                            <input type="radio" className="btn-check" value="nuovo" name="carType" id="option1" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option1">{translate("HomeSelezioneTasto04")}</label>

                        </div>
                        <div className={styles.inputContainer}>
                            <div className="row">
                                <div className="col">
                                    <select className="w-100" style={{ padding: 12 }} onChange={(val) => { setCarBrand(val.target.value); setCarModel(""); }} required >
                                        <option value={""} selected>{translate("HomeSelezioneLista01")}</option>
                                        {filterList.brand ? Object.keys(filterList.brand).map((el) => {
                                            return (<option value={el}>{el}</option>)
                                        })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <select className="w-100" style={{ padding: 12 }} value={carModel} onChange={(val) => { setCarModel(val.target.value); }} required>
                                        <option value={""} selected>{translate("HomeSelezioneLista02")}</option>
                                        {filterList.brand && carBrand ?
                                            filterList.brand[carBrand].map((el) => {
                                                return (<option value={el}>{el}</option>)
                                            })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <Button
                                        style={{ paddingTop: 12, paddingBottom: 12 }}
                                        label={translate("HomeSelezioneTasto05")}
                                        variation={"tertiary"}
                                        onClick={() => navigate('veicoli', {
                                            state: {
                                                type: carType,
                                                brand: carBrand,
                                                model: carModel,
                                                maxPrice: maxPrice
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ maxWidth: 1545, margin: 'auto' }}>
                <div className={"container py-3"}>
                    <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>{translate("HomeNovitaTitolo")}</h2>
                    <MyCarousel
                        desktop={3}
                        showPartial={true}
                        showArrow={true}
                        showArrowTablet={false}
                        showArrowMobile={false}
                        showDots={false}
                        showDotsTablet={true}
                        showDotsMobile={true}
                        containerClass="car-slider"
                    >
                        {carList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-" + i} slideN={i} />
                        })}
                    </MyCarousel>
                    <Button
                        label={translate("HomeOfferteTasto")}
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "veicoli"}
                        style={isMobile ? { margin: "auto", marginTop: 48, fontSize: 13 } : { margin: "auto", marginTop: 48 }}
                    />
                </div>
            </section>

            <section style={{ margin: 'auto', marginBottom: 0, backgroundColor: "#D8DEE9" }}>
                <div className="container-fluid py-5" style={{ maxWidth: 1320 }}>
                    <h2 className="text-center">{translate("HomeGruppoTitolo")}</h2>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow} style={{ marginLeft: -45 }}>
                                <img src="site_images/svg/Shield.svg" width={44} height={44} className={styles.whyIcon} />
                                <h4>{translate("HomeGruppoColonna1-01")}</h4>
                            </div>
                            <div className={styles.whyRow} style={{ marginLeft: -45 }}>
                                <img src="site_images/svg/Marchi.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -25 }}>{translate("HomeGruppoColonna1-02")}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-center">
                            <img src={translateValue("HomeGruppoImg01")} className={styles.whyImage} />
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Finanziamento.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{}}>{translate("HomeGruppoColonna2-01")}</h4>
                            </div>
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Value.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{}}>{translate("HomeGruppoColonna2-02")}</h4>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className={styles.porscheSection}
                //style={isMobile ? { margin:"auto", marginTop:32, fontSize: 13} : {margin:"auto", marginTop:32}}
                style={{ backgroundImage: "url(site_images/bg_home_porsche.png)" }}>
                <div className={"container py-5"}>

                    <h2 style={{ marginBottom: 0 }}>{translate("HomePorscheTitolo")}</h2>
                    <p style={{ marginBottom: 64 }}>{translate("HomePorscheTitoloSub")}</p>

                    <div className={"my-3"} />

                    <MyCarousel
                        desktop={3}
                        tablet={2}
                        mobile={1}
                        showPartial={true}
                    >
                        {porscheList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-porsche-" + i} />
                        })}
                    </MyCarousel>
                    <div className={"my-3 mt-4"} />
                    <Button
                        label={translate("HomePorscheOfferteTasto") }
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "porsche"}
                        style={isMobile ? { margin: "auto", fontSize: 13 } : { margin: "auto" }}
                    />
                </div>
            </section>

            <section style={{ backgroundColor: "#D8DEE9" }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>{translate("HomeNoleggioTitolo")}</h2>
                <MyCarousel
                    desktop={3}
                    showPartial={true}
                    showArrow={false}
                    showArrowTablet={false}
                    showArrowMobile={false}
                    showDots={false}
                    showDotsTablet={false}
                    showDotsMobile={false}
                    infinite={false}
                    containerClass="car-slider"
                >
                    <div className={styles.homeCardContainer} >
                        <div className={styles.homeCard} style={{ backgroundImage: "url(" + translateValue("HomeNoleggio01Img")+")" }} onClick={() => window.location.href = BASENAME + "noleggio-california"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_Rent_Camper_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>{translate("HomeNoleggio01TitoloSub")}</div>
                            <div className={styles.homeCardTitle}>{translate("HomeNoleggio01Titolo")}</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-california"} />
                        </div>
                    </div>

                    <div className={styles.homeCardContainer}>
                        <div className={styles.homeCard} style={{ backgroundImage: "url(" + translateValue("HomeNoleggio02Img") + ")" }} onClick={() => window.location.href = BASENAME + "noleggio-veicoli-commerciali"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_elettrico_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>{translate("HomeNoleggio02TitoloSub")}</div>
                            <div className={styles.homeCardTitle}>{translate("HomeNoleggio02Titolo")}</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-veicoli-commerciali"} />
                        </div>
                    </div>

                    <div className={styles.homeCardContainer}>
                        <div className={styles.homeCard} style={{ backgroundImage: "url(" + translateValue("HomeNoleggio03Img") + ")" }} onClick={() => window.location.href = BASENAME + "noleggio-lungo-termine"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_Import-car_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>{translate("HomeNoleggio03TitoloSub")}</div>
                            <div className={styles.homeCardTitle}>{translate("HomeNoleggio03Titolo")}</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-lungo-termine"} />
                        </div>
                    </div>

                </MyCarousel>
            </section>


            <section style={{ marginTop: -20 }}>
                <h2 className="text-center" style={{ marginBottom: 40 }}>{translate("HomeSpecializzatiTitolo")}</h2>
                <div className={styles.logoContainer}>
                    <div><img src={translateValue("HomeLogoAudi")} /></div>
                    <div><img src={translateValue("HomeLogoVW")} /></div>
                    <div><img src={translateValue("HomeLogoPorsche")} /></div>
                </div>
            </section>
            <section style={{ backgroundColor: "#D8DEE9" }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>{translate("HomeAssistenzaTitolo")}</h2>
                <div className={styles.assistanceCardContainer}>

                    <div className={styles.assistanceCard}>
                        <img className={styles.assistanceCardImg} src={translateValue("HomeAssistenza01ImgMobile")} />
                        <div className={styles.assistanceCardBottomRow}>
                            <img src="site_images/icon/icon_officina.png" />
                            <div className={styles.assistanceCardText}>
                                <h3>{translate("HomeAssistenza01TitoloSub")}</h3>
                                <h4>{translate("HomeAssistenza01Titolo")}</h4>
                            </div>
                            <Button variation={"tertiary"} label={translate("HomeAssistenza01Tasto")} onClick={() => window.location.href = BASENAME + "service"} />
                        </div>
                    </div>

                    <div className={styles.assistanceCard}>
                        <img className={styles.assistanceCardImg} src={translateValue("HomeAssistenza02ImgMobile")} />
                        <div className={styles.assistanceCardBottomRow}>
                            <img src="site_images/icon/icon_preparazione.png" />
                            <div className={styles.assistanceCardText}>
                                <h3>{translate("HomeAssistenza02TitoloSub")}</h3>
                                <h4>{translate("HomeAssistenza02Titolo")}</h4>
                            </div>
                            <Button variation={"tertiary"} label={translate("HomeAssistenza02Tasto")} onClick={() => window.location.href = BASENAME + "carrozzeria"} />
                        </div>
                    </div>


                </div>
            </section>



            <section style={{ marginTop: -20 }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 0, marginBottom: 35 } : null}>{translate("HomeDiconoTitolo")}</h2>
                <div className="text-center mb-4">
                    <img src={translateValue("HomeDiconoImg")} />
                </div>

                <div className={styles.ratingContainer}>
                    <div className={styles.ratingExternalCard}>
                        <h4>{translate("HomeDicono01Titolo")}</h4>
                        <div className={styles.ratingCard}>
                            <div className={styles.rate}>4,9</div>
                            <div className={styles.stars}>
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                            </div>
                            <div className={styles.ratingText}>
                                100% di recensioni positive
                            </div>
                            <img src={translateValue("HomeDicono01Img01")} />
                        </div>
                    </div>

                    <div className={styles.ratingExternalCard}>
                        <h4>{translate("HomeDicono02Titolo")}</h4>
                        <div className={styles.ratingCard}>
                            <div className={styles.rate}>4,6</div>
                            <div className={styles.stars}>
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                            </div>
                            <div className={styles.ratingText}>
                                96% di recensioni positive
                            </div>
                            <img src={translateValue("HomeDicono01Img02")} />
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    );
}

