import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import { translate, translateValue } from '../../helper/functions';



export default function California() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>{translate("NoleggioCamperTitolo")}</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("NoleggioCamperPath01"), link: BASENAME },
                        { label: translate("NoleggioCamperPath02"), link: null },
                        { label: translate("NoleggioCamperPath03"), link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src={translateValue("NoleggioCamper01Img01Mobile")} className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pe-lg-5 d-none d-lg-block">
                            <img src={translateValue("NoleggioCamper01Img01")} className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 ">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper01Testo01") }} />

                                {translate("NoleggioCamper01Testo02").split("|").map((el) => {
                                    return (
                                        <div className={styles.checkmarksContainer}>
                                            <img src="site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el}</h2>
                                            </div>
                                        </div>
                                    )
                                })}


                                <div className={styles.paragraph} style={{ marginTop: 24 }} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper01Testo03") }} />

                                <Button
                                    label={translate("NoleggioCamperTel")}
                                    variation={"4"}
                                    style={{ marginBottom: 40, marginTop: 16 }}
                                    iconRight={["fas", "phone"]}
                                    onClick={() => window.open('tel:' + translate("NoleggioCamperTel"))}
                                />

                                <div className={styles.littleTitle}>{translate("NoleggioCamper01Testo04")}</div>

                                {translate("NoleggioCamper01Testo05").split("|").map((el) => {
                                    return (
                                        <div className={styles.checkmarksContainer}>
                                            <img src="site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el}</h2>
                                            </div>
                                        </div>
                                    )
                                })}



                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.contentContainer} style={{ backgroundColor:"#D8DEE9", marginTop:56}}>
                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src={translateValue("NoleggioCamper02Img01")} className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 ps-lg-5 ">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo01") }} />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo02") }} />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo03") }} />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo04") }} />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo05") }} />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioCamper02Testo06") }} />




                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={translate("NoleggioCamperTel")}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={() => window.open('tel:' + translate("NoleggioCamperTel"))}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pe-lg-5 d-none d-lg-block">
                            <img src="./site_images/california_2.png" className={styles.image} />
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    );
}

