import React, { useState} from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import ReactPlayer from 'react-player'
import { translate, translateValue } from '../../helper/functions';



export default function Commercial() {
    const [play, setPlay] = useState(false);


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>{translate("NoleggioVclCommTitolo")}</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("NoleggioVclCommPath01"), link: BASENAME },
                        { label: translate("NoleggioVclCommPath02"), link: null },
                        { label: translate("NoleggioVclCommPath03"), link: null }
                    ]}
                />
                
                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src={translateValue("NoleggioVclComm01Img01Mobile")} className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-5 pe-lg-5 d-none d-lg-block">
                            <img src={translateValue("NoleggioVclComm01Img01")} className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.titleWithIcon}>
                                    <img src="site_images/Icon_elettrico.png" />
                                    <h2 dangerouslySetInnerHTML={{ __html: translate("NoleggioVclComm01Testo01") }} />
                                </div>
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioVclComm01Testo02") }} />

                                {translate("NoleggioVclComm01Testo03").split("|").map((el) => {
                                    return (
                                        <div className={styles.checkmarksContainer}>
                                            <img src="site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el}</h2>
                                            </div>
                                        </div>
                                    )
                                })}


                                <Button
                                    label={translate("NoleggioVclComm01Tasto01")}
                                    variation={"tertiary"}
                                    style={{ marginBottom: 40, marginTop: 16 }}
                                    onClick={() => {
                                        window.open(
                                            'https://www.gruppomilanoauto.it/OHM_35_BEV_Brochure_GMA.pdf',
                                            '_blank'
                                        );
                                    }}
                                />
                                <div className={styles.miniTitle} dangerouslySetInnerHTML={{ __html: translate("NoleggioVclComm01Testo04") }} />
                                <div className={styles.paragraphBold} dangerouslySetInnerHTML={{ __html: translate("NoleggioVclComm01Testo05") }} />
                                <Button
                                    label={translate("NoleggioVclComm01Tasto02")}
                                    variation={"4"}
                                    style={{ marginBottom: 40 }}
                                    iconRight={["far","envelope"]}
                                    onClick={() => window.open('mailto:' + translate("NoleggioVclComm01Tasto02"))}
                                    />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <ReactPlayer
                url={translateValue("NoleggioVclComm01Img02")}
                width='100%'
                controls={true}
                width='100%'
                height='700px'
            />
        </Layout>
    );
}

