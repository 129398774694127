import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import MyCarousel from '../../components/carousel/carousel';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import { translate, translateValue } from '../../helper/functions';


export default function Service() {
    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const css = `
    .react-multi-carousel-dot-list {
        margin-bottom:5px;
    }
`

    return (
        <Layout>
            <style>{css}</style>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>{translate("AssistenzaServiceTitolo")}</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("AssistenzaServicePath01"), link: BASENAME },
                        { label: translate("AssistenzaServicePath02"), link: null },
                        { label: translate("AssistenzaServicePath03"), link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/service_new_logo_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-5 pe-lg-5 d-none d-lg-block">
                            <img src={translateValue("AssistenzaService01Img01")} className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("AssistenzaService01Testo01") }} />

                                {translate("AssistenzaService01Testo02").split("|").map((el) => {
                                    return (
                                        <div className={styles.checkmarksContainer}>
                                            <img src="site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el}</h2>
                                            </div>
                                        </div>
                                    )
                                })}


                                <div className={styles.paragraph} style={{ marginBottom: 0, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: translate("AssistenzaService01Testo03") }} />
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={translate("AssistenzaServiceTel")}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={() => window.open('tel:' + translate("AssistenzaServiceTel"))}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.contentContainer} style={{ backgroundColor: "#D8DEE9", marginTop: 56 }}>
                <div className={styles.content}>
                    <div className={"d-block d-md-none"}>
                        <MyCarousel
                            responsive={responsive}
                            arrows={false}
                            showDots={false}
                            showDotsTablet={true}
                            showDotsMobile={true}
                        >
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_preparazione.png" />
                                    <h2>{translate("AssistenzaService02Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService02Testo02") }} />
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>{translate("AssistenzaService03Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService03Testo02") }} />
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_lens.png" />
                                    <h2>{translate("AssistenzaService04Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService04Testo02") }} />
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_value_2.png" />
                                    <h2>{translate("AssistenzaService05Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService05Testo02") }} />
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_motore.png" />
                                    <h2>{translate("AssistenzaService06Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService06Testo02") }} />
                            </div>

                        </MyCarousel>

                    </div>
                    <div className="row justify-content-center d-none d-md-flex">
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_preparazione.png" />
                                    <h2>{translate("AssistenzaService02Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService02Testo02") }} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>{translate("AssistenzaService03Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService03Testo02") }} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_lens.png" />
                                    <h2>{translate("AssistenzaService04Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService04Testo02") }} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_value_2.png" />
                                    <h2>{translate("AssistenzaService05Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService05Testo02") }} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_motore.png" />
                                    <h2>{translate("AssistenzaService06Testo01")}</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: translate("AssistenzaService06Testo02") }} />
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className={styles.contentContainer} style={{marginBottom:156}}>
                <div className={styles.brandSection}>
                    <h2>{translate("AssistenzaService07Testo01")}</h2>
                    <div className={styles.brandContainer}>
                        <img src="site_images/brands/bosch.png" />
                        <img src="site_images/brands/brembo.png" />
                        <img src="site_images/brands/castrol.png" />
                        <img src="site_images/brands/continental.png" />
                        <img src="site_images/brands/michelin.png" />
                    </div>
                </div>
            </div>

        </Layout>
    );
}

