export const API_URL = "https://www.gruppomilanoauto.it/backend/";
//export const IMG_URL = "https://codzilla.it/milanoauto/file/img/"
export const IMG_URL = "https://www.gruppomilanoauto.it/img/"

//for development in subfolder
//export const BASENAME = "/milanoauto/";
export const BASENAME = "/";

export const SMTP = "info@gruppomilanoauto.it"

// export const NUOVI_ARRIVI = 30       // rimossa perché gestita da back-end

export const RIGHE_DESC = 10            // definisce il numero di righe 'descrizione veicolo' visibili (prima di espandere la scheda)

export const COOKIE_DURATION_DAYS = 30  // definisce la durata dei cookie

export const COOKIE_BOOKMARKS = "milAutoCookieBook"

export const COOKIE_CONSENT = "milAutoCookieConsent"