import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../../components/hero/hero';

import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';

import parse from 'html-react-parser';
import Content from './readMore';

import styles from "./style.module.scss";
import { ajaxGet, ajaxPost } from "../../helper/ajax";
import Lightbox from "yet-another-react-lightbox";
import { IMG_URL } from "../../constant"
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";

import MyCarousel from '../../components/carousel/carousel';
import CarPreview from "../../components/carPreview/carPreview";

import { toFormat, getCookieForBookmark, setCookieForBookmark, translate, translateValue, toPrice, endPrice } from "../../helper/functions";
import { BASENAME, COOKIE_BOOKMARKS, COOKIE_DURATION_DAYS } from "../../constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {isMobile} from 'react-device-detect';
import ContactForm from '../../components/contactForm/contactForm';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";


export default function Vehicle() {
    const ref = useRef(null);

    const handleClick = () => {
        setContactOpen(true); return;
        const id = 'contact-form';
        const yOffset = -150;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });

        //ref.current ?.scrollIntoView({ behavior: 'smooth' });
    };


    const id = useParams().id;

    const [car, setCar] = useState(null);
    const [galleryOpen, setGalleryOpen] = useState(false);

    const [openLightbox, setOpenLightbox] = React.useState(false);
    const [lightboxIndex, setLightboxIndex] = React.useState(0);

    const [contactOpen, setContactOpen] = useState(false);

    const [bookmarked, setBookmarked] = useState(false);

    const [sectionsEquip, setSectionEquip] = useState([true,true,true,true,true]);
    //const [sectionsButtonEquip, setSectionsButtonEquip] = useState([true,true,true,true,true]);

    const [carListFeatured, setCarListFeatured] = useState([]);

    function getBookmarkedValue(carId) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+carId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === true) {
            //console.log(`Try to check for → ${carId} as Bookmarked`);
            return true
        } else {
            return false
        }
    }


    useEffect(() => {
        if (!car) {
            let formData = new FormData();
            formData.append("id", id);
            //console.log(`Try to call page with id = ${id}`);
            ajaxPost("getVcl.php", formData).then((response) => {
                if (response.code == "100") {
                    setBookmarked(getBookmarkedValue(id));
                    //setBookmarked(isBookmarked());
                    setCar(response.message)
                    //console.log(response.message)
                }
            });
        }

        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                setCarListFeatured(response.message)
            }
        });
    }, [])

    function dotThePrice(carPrice) {
        var priceToArray = [];
        const splitIndex = carPrice.length - 3;
        priceToArray = carPrice.split("");
        priceToArray.splice(splitIndex, 0, ".");
        carPrice = priceToArray.join('');
        return carPrice;
    }

    function addBookmark() {
        if (car) {
            // verificare se il cookie esiste
            var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+car.vcl.VclId);
            // ritorna true o false in base al valore (null → false)
            var cookieFlag = cookieValue ? false : true
            // cambio il valore dell'hook "bookmarked"
            setBookmarked(cookieFlag)
            //console.log(`WRITE ${car.vcl.VclId} → TO ${cookieFlag}`);
            // salva true o false nel cookie (es. nome → milAutoCookieBook14980 )
            setCookieForBookmark(COOKIE_BOOKMARKS+"_"+car.vcl.VclId, cookieFlag, COOKIE_DURATION_DAYS);
        }
    }

    if (!car) { return null }
    const gallery = car.vcl.img.map(el => ({ src: IMG_URL + el.FullPath }));

    const renderAttribute = (title, value, icon) => {
        if (!value) { value = "" }
        return (
            <div className="col-12 col-lg-4 col-xl-3 my-3" key={title + value}>
                <div className={styles.attribute}>
                    <div className={styles.attibuteIcon}>
                        <img src={"../site_images/svg/" + icon} width={40} height={40} />
                    </div>
                    <div className={styles.attibuteText}>
                        <div className={styles.attributeTitle}>{title}</div>
                        <div className={styles.attributeValue}>{value.toLowerCase()}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderOptional = (title, value, index) => {
        if (!value) { value = "" }

        return (
            <div className="col-md-3 col-sm-6 mb-4 d-flex" key={title + value + index}>
                <div className={styles.optional}>
                    <div className={styles.optionalIcon}>
                        <img src={"../site_images/svg/Check.svg"} width={32} height={32} />
                    </div>
                    <div className={styles.optionalText}>
                        <div className={styles.optionalTitle}>{title}</div>
                        <div className={styles.optionalValue}>{value.toLowerCase()}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderOptionalList = (eqp) => {
        let order = ["Comfort", "Esterni", "Interni", "Servizi", "Sicurezza","Altro"]
        let arr = {};

        eqp.map((el) => {
            if (!arr[el.Categoria]) {
                arr[el.Categoria] = { title: el.Categoria, items: [] }
            }
            arr[el.Categoria].items.push(el.Descrizione);
        })

        let renderArr = [];
        for (let i = 0; i < order.length; i++) {
            if (arr[order[i]]) {
                renderArr.push(renderOptionalRow(arr[order[i]].title, arr[order[i]].items, i))
            }
        }
        return renderArr
    }
    
    const renderOptionalRow = (title, items, optSection) => {
        return (
            <div className="row mt-5" style={{borderBottom:"1px solid black"}}>
                <div className="col-12"><h2 className="mb-2">{title}</h2></div>
                <div className="col-12">
                    <div className="row">
                        {items.map((el, index) => {
                            return (renderOptionalItem(el, checkItemLength(items), sectionsEquip[optSection], index))
                        })}
                    </div>
                </div>
                {
                    checkItemLength(items) ?
                    // sectionsEquip[optSection] ? 
                    <Button 
                            label={sectionsEquip[optSection] ? translate("Veicolo01EquipAltriTasto01") : translate("Veicolo01EquipAltriTasto02")}
                        variation="primary"
                        // onClick={() => setSectionEquip[optSection] = !sectionsEquip[optSection]} 
                        onClick={() => handleChangeButtonOptional(optSection)}
                        style={{ margin: 'auto',marginBottom: 30, width:200 }}
                    />
                    : null
                }
            </div>
        )
    }

    const handleChangeButtonOptional = (optSectionPos) => {
        // Creare una copia dell'array
        const newArray = sectionsEquip.slice();
    
        // Modificare il primo elemento della copia in base al parametro
        newArray[optSectionPos] = !newArray[optSectionPos];
    
        // Aggiornare lo stato con la nuova copia
        setSectionEquip(newArray);
    };

    function checkIfOverSixthElement(indexNumber) {
        if (indexNumber > 5) return true
        return false
    }

    const renderOptionalItem = (item, limitRenderElements, buttonMoreOptions, indexOptional) => {
        if (limitRenderElements && (checkIfOverSixthElement(indexOptional)) && buttonMoreOptions) {
            return null
        } else {
            return (
                // <div>
                //     Ciao {indexOptional} - {checkIfOverSixthElement(indexOptional).toString()} → {limitRenderElements.toString()}
                // </div>
                <div className="col-12 col-sm-3 mb-4">
                        <div className={styles.optional}>
                            <div className={styles.optionalIcon}>
                                <img src={"../site_images/svg/Check.svg"} width={24} height={24} />
                            </div>
                            <div className={styles.optionalText}>
                                <div className={styles.optionalValue}>{item}</div>
                            </div>
                        </div>
                </div>
            )
        }
    }

    // function checkMaxEquip(totalEquipNo) {
    //     if (totalEquipNo > (6 * (sectionsEquip ? 10 : 1))) {
    //         return true;
    //     }
    //     return false;
    // }

    function checkItemLength(optionalList) {
        const opt =  optionalList.toString().split(',');
        if (opt.length > 6)
            return true
        return false
    }

    function infoRequest() {
        handleClick()
        const element = document.getElementById('hidden_data');
        setNativeValue(element, "Veicolo: " + car.vcl.VclId+"<br>");
    }

    function setNativeValue(element, value) {
        let lastValue = element.value;
        element.value = value;
        let event = new Event("input", { target: element, bubbles: true });
        // React 15
        event.simulated = true;
        // React 16
        let tracker = element._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        element.dispatchEvent(event);
    }

    function renderTag(el, index) {
        if (!el.Posizione || !el.Link) {
            return null;
        }

        return (
            <div className={styles.tag} key={"tag-" + index}>
                <img src={"../" + el.Link} alt={el.Descrizione}></img>
            </div>
        );
    }

    return (
        <Layout removeContactForm={true}>
            <Lightbox
                index={lightboxIndex}
                open={openLightbox}
                close={() => setOpenLightbox(false)}
                slides={gallery}
                plugins={[Zoom, Thumbnails, Counter]}
            />

            <div
                className={styles.galleryBackDrop}
                style={{
                    backgroundColor: galleryOpen ? "#00000080" : "#00000000",
                    pointerEvents: galleryOpen ? "all" : "none",
                }}
                onClick={() => setGalleryOpen(false)}
            >
                <div
                    className={styles.galleryContainer}
                    style={{
                        top: galleryOpen ? "25%" : "100%",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles.galleryTopRow}>
                        <h4> Galleria</h4>
                        <FontAwesomeIcon icon={"close"} style={{ fontSize: 24, cursor: "pointer" }} onClick={() => setGalleryOpen(false)} />
                    </div>
                    <div className={styles.galleryImageContainer}>
                        {car.vcl.img.map((el, index) => {
                            return <img key={"gallery-" + index} src={IMG_URL + el.FullPath} onClick={() => { setOpenLightbox(true); setLightboxIndex(index) }} />
                        })}
                    </div>
                </div>
            </div>

            <section >
                <div className="container">


                    <Hero
                        onlyBreadcrumb={true}
                        breadcrumb={[
                            { label: translate("VeicoloPath01"), link: BASENAME },
                            { label: translate("VeicoloPath02"), link: BASENAME + "veicoli" },
                            { label: car.vcl.Marca + " " + car.vcl.Modello, link: null }
                        ]}

                    />
                    <div className='mt-4' />

                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="mb-0">{car.vcl.Marca}</h2>
                            <p><span style={{ fontWeight: 'bold' }}>{car.vcl.Modello}</span> &nbsp; → {
                                car.vcl.DescrizioneSynth ?
                                    <span>{car.vcl.DescrizioneSynth.toLowerCase()}</span>
                                    : null
                            }</p>
                        </div>

                        <div className={styles.shareContainer}>
                            <FontAwesomeIcon icon={[bookmarked ? "fas" : "far", "heart"]} onClick={() => addBookmark()} />
                            <img src="../site_images/icon/icon_share.png" onClick={() =>
                                window.open("https://api.whatsapp.com/send?text=https://www.gruppomilanoauto.it/veicolo/" + id)
                            } />

                        </div>

                    </div>

                    <div className={styles.imageContainer}>
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td rowSpan={2} style={{ width: "65%", position:"relative" }}>

                                        <img src={IMG_URL + car.vcl.img[0].FullPath} className={styles.vehicleImageBig} onClick={() => setGalleryOpen(true)} />
                                        <div className={styles.imageOverlay}>
                                            <div className={styles.tagTopLeft}>{car.vcl.tag.map((el, index) => { if (el.Posizione == "SxUp") return renderTag(el, index) })}</div>
                                            <div className={styles.tagTopRight}>{car.vcl.tag.map((el, index) => { if (el.Posizione == "DxUp") return renderTag(el, index) })}</div>
                                            <div className={styles.tagBottomLeft}>{car.vcl.tag.map((el, index) => { if (el.Posizione == "SxDown") return renderTag(el, index) })}</div>
                                            <div className={styles.tagBottomRight}>{car.vcl.tag.map((el, index) => { if (el.Posizione == "DxDown") return renderTag(el, index) })}</div>

                                        </div>

                                    </td>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[1].FullPath} className={styles.vehicleImage} onClick={() => setGalleryOpen(true)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[2].FullPath} className={styles.vehicleImage} onClick={() => setGalleryOpen(true)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={styles.buttonContainerImage}>
                            <Button label={translate("VeicoloTasto01")} variation={"5"} onClick={() => setGalleryOpen(true)} />
                        </div>
                    </div>

                    <div className={styles.imageContainerMobile}>
                        <MyCarousel
                            mobile={1}
                            tablet={1}
                            desktop={1}
                            showDefaultArrow={true}
                        >
                            {car.vcl.img.map((el) => {
                                return (
                                    <img src={IMG_URL + el.FullPath} onClick={() => setGalleryOpen(true)} />
                                )
                            })}
                        </MyCarousel>
                    </div>
                </div>
            </section>




            <section style={{ marginTop: isMobile ? 0 : '-30px', marginBottom: '-10px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12  col-lg-4 mb-5">
                            <div className={styles.priceCard}>
                                {car.vcl.Listino ?
                                    <div style={{ fontSize: 15, color:"#6C86AE" }}>
                                        prezzo a nuovo {toPrice(car.vcl.Listino)}
                                    </div>
                                    : null
                                }

                                <div className={styles.prezzo}><small>{translate("Veicolo01PrezzoApice")}</small>
                                    {(car.vcl.PrezzoScontato) ?
                                        <span style={{ marginLeft:8, color: "#BECADD", fontSize: 15, textDecoration:"line-through"}}>{toPrice(car.vcl.PrezzoFinale)}</span>
                                        : null
                                    }
                                </div>
                                
                                <div className={styles.priceText}>{toPrice(endPrice(car.vcl))}</div>
                                <p className={styles.prezzo}>
                                    <bottom>
                                        {translate("Veicolo01PrezzoPedice")}
                                    </bottom>
                                </p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                <div className={styles.priceCardButtonContainer}>
                                    <Button label={isMobile ? "Richiedi info" : translate("Veicolo01PrezzoTasto01")} variation={"tertiary"} onClick={() => infoRequest()} />
                                    <Button icon={"phone"} variation={"secondaryNoB"} onClick={() => window.open("tel:022540348")} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 mb-5">
                            <div className={styles.attributeContainer}>
                                <div className={"row"}>
                                    {renderAttribute("Immatricolazione", car.vcl.Immatricolazione, "Immatricolazione.svg")}
                                    {renderAttribute("Alimentazione", car.vcl.Alimentazione, "Alimentazione.svg")}
                                    {renderAttribute("Cilindrata", toFormat(car.vcl.Cilindrata), "Cilindrata_potenza.svg")}
                                    {renderAttribute("Potenza", car.vcl.Kw + " kW (" + car.vcl.Cv + " CV)", "Cilindrata_potenza.svg")}
                                    {renderAttribute("Cambio", car.vcl.Cambio, "Cambio.svg")}
                                    {renderAttribute("Colore", car.vcl.CarrColore, "Colore.svg")}
                                    {renderAttribute("Chilometraggio", toFormat(car.vcl.Percorrenza), "Chilometraggio.svg")}
                                    {renderAttribute("Tipologia", car.vcl.ClasseComm, "Tipologia.svg")}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section>
                <div className="container">
                    <div className="col-12">
                        <h4 className="mb-4">{translate("Veicolo01EquipTesto01")}</h4>
                        {renderOptionalList(car.vcl.eqp)}
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <h4>Descrizione del veicolo</h4>
                    <p>
                        <div className="my-3" >
                            <div>
                                <div className={styles.attibuteText}>
                                    {
                                        car.vcl.InfoAggiuntive ?
                                            <div numberoflines={3} className={styles.attributeValue}>
                                                <Content descriptionText={parse(car.vcl.InfoAggiuntive)} />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            </section>



            {/*
            <section>
                <div className="container">
                    <div className="col-12">
                        <h4 className="mb-4">Equipaggiamenti Optional</h4>
                        <div className="row">
                            {
                                car.vcl.eqp.map((el, index) => {
                                    // check index number to set how many options must to be rendered
                                    if (index < sectionsEquip * 12) {
                                        return renderOptional(el.Descrizione, el.Categoria, index)
                                    }
                                })
                            }
                            <div className={styles.buttonContainerImage}>
                                {
                                    checkMaxEquip(car.vcl.eqp.length) ?
                                        // (car.vcl.eqp.length > (12*sectionsEquip))
                                        <Button label={`Altro`} variation={"5"} onClick={() => setSectionEquip(sectionsEquip + 1)} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <section style={{ marginTop: '-80px', marginBottom: '-16px' }}>
                <div className="container my-5">
                    <div className={styles.contactCard}>
                        <h4>{translate("Veicolo02Testo01")}</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque semper ac orci in vulputate.</p> */}
                        <div className={styles.contactCardButtonContainer}>
                            <Button label={translate("Veicolo02Tasto01")} variation="tertiary" onClick={handleClick} />
                            {/* <Button label={"Prenota un test drive"} variation="tertiary" /> */}
                        </div>
                    </div>
                </div>
            </section>




            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <div className="row">
                        <div className="col-12 col-lg-5 pe-lg-5">
                            <img src={"../" + translateValue("Veicolo03Img01")} className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.titleWithIcon}>
                                    <img src="../site_images/icon/icon_finanziaria.png" />
                                    <h2 dangerouslySetInnerHTML={{ __html: translate("Veicolo03Testo01") }} />
                                </div>

                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("Veicolo03Testo02") }} />

                                {translate("Veicolo03Testo03").split("|").map((el) => {
                                    return (
                                        <div className={styles.checkmarksContainer}>
                                            <img src="../site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el}</h2>
                                            </div>
                                        </div>
                                    )
                                })}


                                <div className={styles.paragraph} style={{ marginBottom: 0, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: translate("Veicolo03Testo04") }} />
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={translate("Veicolo03Tel")}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={() => window.open('tel:' + translate("Veicolo03Tel"))}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open(translateValue("Veicolo03Wap"))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








            <section style={{ maxWidth: 1545, margin: 'auto', marginBottom:35 }}>
                <h2 className="text-center mt-5 mb-4">Potresti anche essere interessato a questi veicoli</h2>
                <MyCarousel
                    showArrow={true}
                    containerClass="car-slider"
                    showPartial={true}
                >
                    {carListFeatured.map((el, i) => {
                        return <CarPreview data={el} key={"car-preview-" + i} />
                    })}
                </MyCarousel>
            </section>
            {/* div usato per effettuare lo scroll su form al premere dei pulsanti nel riquadro "Richiedi Info" */}
            <div ref={ref} />

            <div
                className={styles.contactBackDrop}
                style={{
                    backgroundColor: contactOpen ? "#00000080" : "#00000000",
                    display: contactOpen ? "block" : "none",
                    pointerEvents: contactOpen ? "all" : "none",
                }}
                onClick={() => setContactOpen(false)}
            >
                <div className={contactOpen ? "" : "d-none"} style={{ marginTop: 80 }}>
                    <ContactForm />
                </div>
            </div>

        </Layout>
    );
}

