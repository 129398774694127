import React, {useState, useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import Iubenda from 'react-iubenda-policy'

import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Button from "../../components/button/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import markerLogo from '../../img/icon-sede.png'
import iconLocation from '../../img/icon_location.png'
import headerLink from "../../components/header/headerLink";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';

import { SMTP } from "../../constant"
import { ajaxGet, ajaxPost } from "../../helper/ajax";

import styles from "./style.module.scss";
import { translate, translateValue } from '../../helper/functions';

export default function Contacts() {
    const myPolicy = 29122716

    // Call when page is rendered, it set windows size fore the used device
    useEffect(() => {
        // Function to update windowWidth when the window is resized
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const form = useRef();

    const [sending, setSending] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        if (sending) { return }
        console.log("invio")

        setSending(true);


        let formdata = new FormData();
        formdata.append("name", user_name);
        formdata.append("surname", user_surname);
        formdata.append("phone", user_telephone);
        formdata.append("email", user_email);
        formdata.append("subject", user_reqType);
        formdata.append("message", user_message);
        formdata.append("hidden_data", "");
        ajaxPost("sendMail.php", formdata).then((response) => {
            setSending(false);

            if (response.code == "100") {
                window.location.href = BASENAME + "thank-you";

                setSuccessMessage(true)
                setErrorMessage(false)

                setName("");
                setSurname("");
                setTelephone("");
                setEmail("");
                setMessage("");
                setReqType("");
            } else {
                setErrorMessage(true)
                setSuccessMessage(false)
            }
        });



        /*
        emailjs
        .sendForm('service_qv028gz', 'template_u42dv5g', "#myform", {
            publicKey: 'xa-Lt3NEEoYbCFzZt',
        })
        .then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );*/
    };
    // funzione per modificare il valore dei checkbox
    const handleChangeButtonOptional = (optCheckboxPos) => {
        // Creare una copia dell'array
        const newArray = mandatoryTerms.slice();
    
        // Modificare il primo elemento della copia in base al parametro
        newArray[optCheckboxPos] = !newArray[optCheckboxPos];
    
        // Aggiornare lo stato con la nuova copia
        setMandatoryTerms(newArray);
    };

    const [user_name, setName] = useState("");
    const [user_surname, setSurname] = useState("");
    const [user_telephone, setTelephone] = useState("");
    const [user_email, setEmail] = useState("");
    const [user_reqType, setReqType] = useState("");
    const [user_message, setMessage] = useState("");
    // hook per la gestione dei 3 checkbox
    const [mandatoryTerms, setMandatoryTerms] = useState([false,false,false]);
    // hook per la gestione del messaggio di errore
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);

    // per il controllo della larghezza dello schermo
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const marker1 = {
        lat: 45.54124660267275,
        lng: 9.288067900000001
    }

    const marker2 = {
        lat: 45.545721264819726,
        lng: 9.164600770312765
    }

    function checkEmail()    {
        console.log("checkEmail Called on 'contact.js'");
    }

    function checkIpad(screenWidth) {
        if (screenWidth < 1200)
            return true
        return false
    }

    // calcola la 
    function returnPercentage(x)
    {
        var part_1 = Math.sqrt(0.5 * x);
        var part_2 = 0.08 * x + (x/4*0.08);       
        var result =  part_1 - part_2
        console.log(` % of gap → ${result}`)
        return result
    }



    return (
        <Layout
            removeContactForm={true}
        >
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle} dangerouslySetInnerHTML={{ __html: translate("ContattiSediTitolo") }} />
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("ContattiSediPath01"), link: BASENAME },
                        { label: translate("ContattiSediPath02"), link: null }
                    ]}
                />
            </div>
            <div className={styles.contentContainer} style={{ backgroundColor: isMobile ?"#e6eaf1":"#D8DEE9" }}>
                <div className={styles.content} >
                    <div className="row" style={{ maxWidth: '1320px' }}>
                        <div className="col-12 col-lg-6 mb-4">
                            <h2 className="mb-3 d-none d-lg-block" dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Testo01") }} />
                            <div className="row align-items-center">
                                <div className={isMobile ? "col-12 col-sm-6 d-none d-lg-block ms-0 mt-0" : checkIpad(windowWidth) ? "col-12 col-sm-6 d-none d-lg-block ms-0 mt-0" : "col-12 col-lg-6"} style={isMobile ? null : { marginLeft: returnPercentage(windowWidth) }}>
                                    {/* <div className="col-12 col-lg-6" style={{marginLeft: returnPercentage(windowWidth)}}> */}
                                    {/* <div className={"col-12 col-sm-6 d-none d-lg-block ms-0 mt-0"}> */}
                                    <img src={translateValue("ContattiSedi01Img01")} className="w-100 ms-n3" alt="Contact Image" />
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className={styles.contactsContainer}>
                                        <img src="../site_images/svg/Phone_blu_scuro.svg" width={26} height={26} fill="white" style={{ textAlign: 'right', marginRight: 10 }} />
                                        {/* <FontAwesomeIcon icon={["fas", "phone"]} className={styles.contactsContainerIcon} /> */}
                                        <div onClick={() => window.open('tel:+39' + translate("ContattiSedi01Tel02"))} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsLabel} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Tel01") }} />
                                            <p className={styles.contactsValue} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Tel02") }} />
                                        </div>
                                    </div>
                                    <div className={styles.contactsContainer}>
                                        <FontAwesomeIcon icon={["far", "envelope"]} className={styles.contactsContainerIcon} />
                                        <div onClick={() => window.open('mailto:' + translate("ContattiSedi01Mail02"))} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsLabel} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Mail01") }}/>
                                            <p className={styles.contactsValue} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Mail02") }} />
                                        </div>
                                    </div>
                                    <div className={styles.contactsContainer}>
                                        <FontAwesomeIcon icon={["fab", "whatsapp"]} className={styles.contactsContainerIcon} />
                                        <div onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsLabel} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Wap01") }} />
                                            <p className={styles.contactsValue} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi01Wap02") }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-5">
                            <div className="d-block d-lg-none">
                                <Button label="COMPILA MODULO" variation="tertiary" style={{ width: "100%" }} onClick={() => setPopupOpen(true)} />
                            </div>
                            <div className={styles.mobilePopupMenu} style={{ left: popupOpen?"0%":"100%"}}>
                            <div className={styles.contactFormContainer}>
                                    <div className={styles.closeButton} onClick={() => setPopupOpen(false)}>
                                        x
                                    </div>
                                    <h2 className="mb-3" dangerouslySetInnerHTML={{ __html: translate("ContattiSedi02Testo01") }} />
                                    <p dangerouslySetInnerHTML={{ __html: translate("ContattiSedi02Testo02") }} />
                                <form ref={form} onSubmit={sendEmail} id='myform'>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                                <input placeholder={translate("ContattiSedi02Nome")} value={user_name} name="user_name" type='text' required="required" onChange={txt => setName(txt.target.value)} style={{ outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                                <input placeholder={translate("ContattiSedi02Cognome")} value={user_surname} name="user_surname" required="required" type='text' onChange={txt => setSurname(txt.target.value)} style={{ outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                                <input placeholder={translate("ContattiSedi02Tel")} value={user_telephone} name="user_telephone" required="required" type='tel' onChange={txt => setTelephone(txt.target.value)} style={{ outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                                <input placeholder={translate("ContattiSedi02Mail")} value={user_email} name="user_email" type='email' required="required" onChange={txt => setEmail(txt.target.value)} style={{ outline: 'none' }} />
                                        </div>
                                        <div className={"col-12"}>
                                            <select required value={user_reqType} name="user_reqType" onChange={txt => { setReqType(txt.target.value) }}>
                                                    <option value="">{translate("ContattiSedi02Tipo")}</option>
                                                    <option >{translate("ContattiSedi02Tipo01")}</option>
                                                    <option>{translate("ContattiSedi02Tipo02")}</option>
                                                    <option>{translate("ContattiSedi02Tipo03")}</option>
                                                    <option>{translate("ContattiSedi02Tipo04")}</option>
                                                    <option>{translate("ContattiSedi02Tipo05")}</option>
                                            </select>
                                        </div>
                                        <div className={"col-12"}>
                                                <textarea placeholder={translate("ContattiSedi02Corpo")} rows="5" type='text' onChange={txt => setMessage(txt.target.value)} style={{ outline: 'none' }}></textarea>
                                        </div>
                                        <div className={"col-12 mb-2 d-flex"} style={isMobile ? { marginTop: -10 } : null}>
                                            <input required="required" type="checkbox" id="contact-form-terms" value="1" onClick={handleChangeButtonOptional} />
                                            <label htmlFor="contact-form-terms">
                                                    {translate("ContattiSedi02Consenso")} <a href="/privacy-policy">Privacy Policy</a>
                                            </label>
                                        </div>
                                        {
                                            errorMessage ? (
                                                <label style={{ color: 'red', fontWeight: 500 }}>Errore durante l'invio</label>
                                            ) : null
                                        }
                                        {
                                            successMessage ? (
                                                <label style={{ color: 'green', fontWeight: 500 }}>Email inviata con successo</label>
                                            ) : null
                                        }


                                        {
                                            isMobile ?
                                                    <Button label={translate("ContattiSedi02Tasto01")} variation="tertiary" type="submit" style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                                                :
                                                    <Button label={translate("ContattiSedi02Tasto01")} variation="tertiary" iconRight="arrow-right" type="submit" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>


            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <h2 style={{marginBottom:32}}>Le nostre sedi</h2>
                    <div className="row">


                        <div className="col-12 col-md-6">
                            <div className={styles.locationCard}>
                                <div className={styles.locationCardImageContainer}>
                                    <img src={translateValue("ContattiSedi03Img01")} className="d-none d-md-block" />
                                    <img src={translateValue("ContattiSedi03Img01Mobile")} className="d-block d-md-none" />
                                </div>
                                <div className={styles.locationCardTextContainer}>
                                    <div className={styles.locationCardTitle} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi03Testo01") }} />
                                    <div className={styles.locationCardTitle2} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi03Testo02") }} />
                                    <div className={styles.locationCardTitle3} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi03Testo03") }} />
                                    <div className={styles.locationCardHours} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi03Testo04") }} />
                                    <div className={styles.locationCardHoursText} >
                                        {translate("ContattiSedi03Testo05")}<br />{translate("ContattiSedi03Testo06")}
                                    </div>
                                    <div className={styles.locationCardLocation}>
                                        <img src="site_images/icon/new_icon_location.png" />
                                        <div dangerouslySetInnerHTML={{ __html: translate("ContattiSedi03Testo07") }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className={styles.locationCard}>
                                <div className={styles.locationCardImageContainer}>
                                    <img src={translateValue("ContattiSedi04Img01")} className="d-none d-md-block" />
                                    <img src={translateValue("ContattiSedi04Img01Mobile")} className="d-block d-md-none" />
                                </div>
                                <div className={styles.locationCardTextContainer}>
                                    <div className={styles.locationCardTitle} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi04Testo01") }} />
                                    <div className={styles.locationCardTitle2} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi04Testo02") }} />
                                    <div className={styles.locationCardTitle3} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi04Testo03") }} />
                                    <div className={styles.locationCardHours} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi04Testo04") }} />
                                    <div className={styles.locationCardHoursText} >
                                        {translate("ContattiSedi04Testo05")}<br />{translate("ContattiSedi04Testo06")}
                                    </div>
                                    <div className={styles.locationCardLocation}>
                                        <img src="site_images/icon/new_icon_location.png" />
                                        <div dangerouslySetInnerHTML={{ __html: translate("ContattiSedi04Testo07") }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className={styles.locationCard}>
                                <div className={styles.locationCardImageContainer}>
                                    <img src={translateValue("ContattiSedi05Img01")} className="d-none d-md-block" />
                                    <img src={translateValue("ContattiSedi05Img01Mobile")} className="d-block d-md-none" />
                                </div>
                                <div className={styles.locationCardTextContainer}>
                                    <div className={styles.locationCardTitle} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi05Testo01") }} />
                                    <div className={styles.locationCardTitle2} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi05Testo02") }} />
                                    <div className={styles.locationCardTitle3} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi05Testo03") }} />
                                    <div className={styles.locationCardHours} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi05Testo04") }} />
                                    <div className={styles.locationCardHoursText} >
                                        {translate("ContattiSedi05Testo05")}<br />{translate("ContattiSedi05Testo06")}
                                    </div>
                                    <div className={styles.locationCardLocation}>
                                        <img src="site_images/icon/new_icon_location.png" />
                                        <div dangerouslySetInnerHTML={{ __html: translate("ContattiSedi05Testo07") }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className={styles.locationCard}>
                                <div className={styles.locationCardImageContainer}>
                                    <img src={translateValue("ContattiSedi06Img01")} className="d-none d-md-block" />
                                    <img src={translateValue("ContattiSedi06Img01Mobile")} className="d-block d-md-none" />
                                </div>
                                <div className={styles.locationCardTextContainer}>
                                    <div className={styles.locationCardTitle} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi06Testo01") }} />
                                    <div className={styles.locationCardTitle2} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi06Testo02") }} />
                                    <div className={styles.locationCardTitle3} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi06Testo03") }} />
                                    <div className={styles.locationCardHours} dangerouslySetInnerHTML={{ __html: translate("ContattiSedi06Testo04") }} />
                                    <div className={styles.locationCardHoursText} >
                                        {translate("ContattiSedi06Testo05")}<br />{translate("ContattiSedi06Testo06")}
                                    </div>
                                    <div className={styles.locationCardLocation}>
                                        <img src="site_images/icon/new_icon_location.png" />
                                        <div dangerouslySetInnerHTML={{ __html: translate("ContattiSedi06Testo07") }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginBottom: 50}}/>
        </Layout>
    );
}
