import React, { useState, useEffect, useRef } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';

import styles from "./style.module.scss";
import CarPreview from "../../components/carPreview/carPreview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ajaxGet, ajaxPost } from "../../helper/ajax";
import { useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { FilterMobile } from './filterMobile.js';

import { COOKIE_BOOKMARKS } from "../../constant"
import { getCookieForBookmark, translate, translateValue } from "../../helper/functions";
import {isMobile} from 'react-device-detect';
import { MultiSelect } from "react-multi-select-component";

export default function Listing() {
    const location = useLocation();

    const [count, setCount] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false);

    const [carList, setCarList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [filterList, setFilterList] = useState({});

    const [carName, setCarName] = useState(location.state?.search ? location.state.search : "");
    const [carType, setCarType] = useState(location.state?.type ? location.state.type : null);
    const [carBrand, setCarBrand] = useState(location.state?.brand ? location.state.brand : null);
    const [carModel, setCarModel] = useState(location.state?.model ? location.state.model : null);
    const [carBody, setCarBody] = useState(null);
    const [carFuel, setCarFuel] = useState(null);
    const [carYear, setCarYear] = useState({ from: null, to: null });
    const [carTransmission, setCarTransmission] = useState([]);
    const [carKm, setCarKm] = useState({ from: null, to: null });
    const [carColor, setCarColor] = useState(null);
    const [maxPrice, setMaxPrice] = useState(location.state?.maxPrice ? location.state.maxPrice : null);
    const [orderBy, setOrderBy] = useState(translate("RisRicercaOrdinaDefault"));
    const itemsPerPage = 9;


    const [showModalSaveSearch, setShowModalSaveSearch] = useState(false);
    const [showModalSaveSearchConfirmed, setShowModalSaveSearchConfirmed] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [termAccepted, setTermAccepted] = useState(false);

    const [errorEmail, setErrorEmail] = useState("");
    const [errorPhone, setErrorPhone] = useState("");
    const [errorTerm, setErrorTerm] = useState("");

    const [ip, setIp] = useState(null);

    //const [searchVersion, setSearchVersion] = useState(0);


    const mobileFilter = useRef(null);

    useEffect(() => {
        if (!ip) {
            fetch('https://geolocation-db.com/json/')
                .then(response => response.json())
                .then(data => { setIp(data.IPv4)})
                .catch(error => console.log(error))
        }
        search();
        if (Object.keys(filterList).length == 0) {
            ajaxGet("getFilters.php").then((response) => {
                if (response.code == "100") {
                    setFilterList(response.message);
                }
            });
        }

    }, [carBrand, carName, carType, carModel, carBody, carFuel, carYear, carTransmission, carKm, carColor, currentPage, maxPrice, orderBy])

    function search() {
        saveResearch();
        let formdata = new FormData();
        formdata.append("search", carName)

        if (carBrand) { formdata.append("brand", carBrand) }
        if (carType) { formdata.append("type", carType) }
        if (carModel) { formdata.append("model", carModel) }
        if (carName) { formdata.append("name", carName) }
        if (carBody) { formdata.append("body", carBody) }
        if (carFuel) { formdata.append("fuel", carFuel) }
        if (carYear.from) { formdata.append("year_from", carYear.from) }
        if (carYear.to) { formdata.append("year_to", carYear.to) }
        if (carKm.from) { formdata.append("km_from", carKm.from) }
        if (carKm.to) { formdata.append("km_to", carKm.to) }
        if (carColor) { formdata.append("color", carColor) }
        if (maxPrice) { formdata.append("max_price", maxPrice) }
        if (orderBy) { formdata.append("order_by", orderBy) }
        if (carTransmission.length > 0) { formdata.append("transmission", carTransmission) }

        formdata.append("limit", itemsPerPage);
        formdata.append("offset", currentPage * itemsPerPage);

        ajaxPost("getVclList.php", formdata).then((response) => {

            if (response.code == "100") {
                setCarList(response.message.car);
                setCount(response.message.total);
                setPageLoaded(true)
            }
        });
    }

    function loadingPage() {
        setCarList([]);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => {
            window.scroll({ top: -1, left: 0, behavior: "smooth" });
        }, 10);

        setPageLoaded(false)
    }

    function renderPagination(totCars) {
        if (count == 0) { return null }
        let numberOfPages = Math.ceil(count / itemsPerPage);

        if (currentPage >= numberOfPages) { setCurrentPage(0) }

        let arr = [];
        arr.push(<button key={"pagination-prev"} className={styles.paginationPrev} onClick={() => {
            if (currentPage == 0) { return }
            setCurrentPage(currentPage - 1)
            loadingPage();
        }}>
            <img src="site_images/icon/icon-left-arrow.png" />
        </button>);

        let showEmpty = true;
        for (let i = 0; i < numberOfPages; i++) {
            let showPage = false;

            if (Math.abs(currentPage - i) < 3) { showPage = true; }
            if (i == 0 || i == numberOfPages - 1) { showPage = true; }

            if (showPage) {
                arr.push(<button key={"pagination-" + i} className={(currentPage == i) ? styles.paginationPageActive : styles.paginationPage} onClick={() => {
                    if (currentPage == i) { return }
                    setCurrentPage(i);
                    loadingPage();
                }}>
                    {i + 1}
                </button>);
                showEmpty = true;
            } else {
                if (showEmpty) {
                    arr.push(<button key={"pagination-" + i} className={styles.paginationPage}>...</button>);
                    showEmpty = false;
                }
            }
        }

        arr.push(<button key={"pagination-next"} className={styles.paginationNext} onClick={() => {
            if (currentPage == numberOfPages - 1) { return }
            setCurrentPage(currentPage + 1)
            loadingPage();
        }}>
            <img src="site_images/icon/icon-right-arrow.png" />
        </button>);

        if (arr.length <= 2) {
            return null;
        }

        return (
            <div className={styles.paginationContainer}>
                {arr}
            </div>
        );
    }

    function renderYearOption(from = true) {
        let arr = []
        let lastYear = new Date().getFullYear()
        for (let i = 0; i < 100; i++) {
            if (!from) {
                if (carYear.from) {
                    if (carYear.from > lastYear) {
                        break;
                    }
                }
            } else {
                if (carYear.to) {
                    if (carYear.to < lastYear) {
                        lastYear--;
                        continue;
                    }
                }

            }

            arr.push(<option value={lastYear}>{lastYear}</option>)
            lastYear--;
        }

        if (arr.length == 0) { return null }
        return arr;
    }

    function renderKmOption(from = true) {
        let arr = []
        let kmArr = [2500, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 150000, 175000, 200000];

        for (let i = 0; i < kmArr.length; i++) {
            if (!from) {
                if (carKm.from) {
                    if (carKm.from > kmArr[i]) {
                        continue;
                    }
                }
            } else {
                if (carKm.to) {
                    if (carKm.to < kmArr[i]) {
                        continue;
                    }
                }

            }

            arr.push(<option value={kmArr[i]} >{kmArr[i].toLocaleString("it-IT") + " km"}</option>)
        }

        if (arr.length == 0) { return null }
        return arr;
    }


    function multiselectSetOptionsArray(arr) {
        if (!arr) { return [] }

        let temp = [];
        arr.map((el) => {
            if (!el) {return }
            temp.push({
                label: el.toUpperCase(),
                value:el,
            })
        })

        return temp;
    }

    function multiselectSetValue(val, func) {
        let s = "";
        val.map((el, index) => {
            if (index > 0) {s+="|" }
            s += el.value
        })
        func(s);
    }

    function multiselectGetValue(arr) {
        let temp = [];
        if (!arr) {return temp }
        let explode = arr.split("|");
        explode.map((el) => {
            if (el == "") {return }
            temp.push({
                label: el.toUpperCase(),
                value: el,
            })
        })
        return temp
    }



    function multiselectSetOptionsArrayBrand() {
        let temp = []
        let brands = carBrand?carBrand.split("|"):[];
        brands.map((brand) => {
            if (filterList.brand) {
                if (filterList.brand[brand]) {
                    filterList.brand[brand].map((el) => {
                        temp.push({
                            label: el.toUpperCase(),
                            value: el,
                        })
                    })
                }
            }
        })

        return temp;
    }


    function renderFilters() {
        if (!filterList.brand) {return null }
        return (
            <div className={styles.filterContainer}>
                <input className="w-100" placeholder={translate("RisRicercaFiltroCerca")} onBlur={(val) => { setCarName(val.target.value); }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.target.blur();
                        }
                    }}
                />



                <h4>{translate("RisRicercaFiltroTipologiaTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArray(filterList.type)}
                    value={multiselectGetValue(carType)}
                    onChange={(val) => { multiselectSetValue(val, setCarType) }}
                    labelledBy="Tipologia"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />

                <h4>{translate("RisRicercaFiltroMarcaTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArray(Object.keys(filterList.brand).map((el) => { return el}))}
                    value={multiselectGetValue(carBrand)}
                    onChange={(val) => { multiselectSetValue(val, setCarBrand) }}
                    labelledBy="Marca"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />

                <h4>{translate("RisRicercaFiltroModelloTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArrayBrand()}
                    value={multiselectGetValue(carModel)}
                    onChange={(val) => { multiselectSetValue(val, setCarModel) }}
                    labelledBy="Modello"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />


                <h4>{translate("RisRicercaFiltroCarrozeriaTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArray(filterList.body)}
                    value={multiselectGetValue(carBody)}
                    onChange={(val) => { multiselectSetValue(val, setCarBody) }}
                    labelledBy="Carrozzeria"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />

                <h4>{translate("RisRicercaFiltroCarburanteTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArray(filterList.fuel)}
                    value={multiselectGetValue(carFuel)}
                    onChange={(val) => { multiselectSetValue(val, setCarFuel) }}
                    labelledBy="Carburante"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />

                <h4>{translate("RisRicercaFiltroAnnoTitolo")}</h4>
                <div className="row">
                    <div className="col-6">
                        <select required className="w-100" value={carYear.from} onChange={(val) => setCarYear({ from: val.target.value, to: carYear.to })} >
                            <option value={""} selected>{translate("RisRicercaFiltroAnno01")}</option>
                            {renderYearOption()}
                        </select>
                    </div>
                    <div className="col-6">
                        <select required className="w-100" value={carYear.to} onChange={(val) => setCarYear({ from: carYear.from, to: val.target.value })} >
                            <option value={""} selected>{translate("RisRicercaFiltroAnno02")}</option>
                            {renderYearOption(false)}
                        </select>
                    </div>
                </div>

                <h4>{translate("RisRicercaFiltroCambioTitolo")}</h4>
                <div className="row">
                    {
                        filterList.transmission ? filterList.transmission.map((el) => {
                            if (!el) { return }
                            return (
                                <div className="col-auto">
                                    <input type="checkbox"
                                        name="transmission"
                                        value={el}
                                        onChange={(val) => {
                                            let temp = [...carTransmission];
                                            if (temp.includes(val.target.value)) {
                                                if (!val.target.checked) {
                                                    temp.splice(temp.indexOf(el), 1)
                                                }
                                            } else {
                                                if (val.target.checked) {
                                                    temp.push(el)
                                                }
                                            }
                                            setCarTransmission(temp);
                                        }}
                                    />
                                    {el[0].toUpperCase() + el.slice(1)}
                                </div>
                            )
                        }) : null
                    }
                </div>

                <h4>{translate("RisRicercaFiltroChilometriTitolo")}</h4>
                <div className="row">
                    <div className="col-6">
                        <select required className="w-100" value={carKm.from} onChange={(val) => setCarKm({ from: val.target.value, to: carKm.to })} >
                            <option value={""} selected>{translate("RisRicercaFiltroChilometri01")}</option>
                            {renderKmOption()}
                        </select>
                    </div>
                    <div className="col-6">
                        <select required className="w-100" value={carKm.to} onChange={(val) => setCarKm({ from: carKm.from, to: val.target.value })} >
                            <option value={""} selected>{translate("RisRicercaFiltroChilometri02")}</option>
                            {renderKmOption(false)}
                        </select>
                    </div>
                </div>


                <h4>{translate("RisRicercaFiltroColoreTitolo")}</h4>
                <MultiSelect
                    options={multiselectSetOptionsArray(filterList.color)}
                    value={multiselectGetValue(carColor)}
                    onChange={(val) => { multiselectSetValue(val, setCarColor) }}
                    labelledBy="Colore"
                    hasSelectAll={false}
                    overrideStrings={{ allItemsAreSelected: "Tutto", noOptions: "Nessun risultato", search: "Cerca", selectAll: "Seleziona tutti", selectSomeItems: "Tutto" }}
                    disableSearch
                />

                <h4>{translate("RisRicercaFiltroPrezzoMaxTitolo")}</h4>
                <select required className="w-100" value={maxPrice} onChange={(val) => setMaxPrice(val.target.value)} >
                    <option value={""} selected>{translate("RisRicercaFiltroPrezzoMax01")}</option>
                    <option value={10000}>10.000 €</option>
                    <option value={15000}>15.000 €</option>
                    <option value={20000}>20.000 €</option>
                    <option value={25000}>25.000 €</option>
                    <option value={30000}>30.000 €</option>
                    <option value={35000}>35.000 €</option>
                    <option value={40000}>40.000 €</option>
                    <option value={50000}>50.000 €</option>
                    <option value={60000}>60.000 €</option>
                    <option value={70000}>70.000 €</option>
                </select>
            </div>

        )
    }

    function renderMobileFilterButton() {
        return (
            <div>
                {(carName) ? singleMobileFilterButton(carName, setCarName, "") : null}
                {(carBrand) ? singleMobileFilterButton(carBrand, setCarBrand, null) : null}
                {(carType) ? singleMobileFilterButton(carType, setCarType, null) : null}
                {(carModel) ? singleMobileFilterButton(carModel, setCarModel, null) : null}
                {(carBody) ? singleMobileFilterButton(carBody, setCarBody, null) : null}
                {(carFuel) ? singleMobileFilterButton(carFuel, setCarFuel, null) : null}

                {(carColor) ? singleMobileFilterButton(carColor, setCarColor, null) : null}
                {(maxPrice) ? singleMobileFilterButton(maxPrice, setMaxPrice, null) : null}
            </div>
        )
    }

    function singleMobileFilterButton(val, resetFunction, resetValue) {
        return (
            <button className={styles.mobileFilterButton}>
                {val}
                <img src="./site_images/icon/icon-close.png" onClick={() => resetFunction(resetValue)} />
            </button>
        )
    }


    function checkBookmark(carId) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS + "_" + carId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === true) {
            //console.log(`Return car → ${carId} as Bookmarked`);
            return true
        } else {
            return false
        }
    }

    function validateEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }



    function saveResearch(requested = false) {

        let formdata = new FormData();
        formdata.append("search", carName)

        if (carBrand) { formdata.append("brand", carBrand) }
        if (carType) { formdata.append("type", carType) }
        if (carModel) { formdata.append("model", carModel) }
        if (carName) { formdata.append("name", carName) }
        if (carBody) { formdata.append("body", carBody) }
        if (carFuel) { formdata.append("fuel", carFuel) }
        if (carYear.from) { formdata.append("year_from", carYear.from) }
        if (carYear.to) { formdata.append("year_to", carYear.to) }
        if (carKm.from) { formdata.append("km_from", carKm.from) }
        if (carKm.to) { formdata.append("km_to", carKm.to) }
        if (carColor) { formdata.append("color", carColor) }
        if (maxPrice) { formdata.append("max_price", maxPrice) }
        if (carTransmission.length > 0) { formdata.append("transmission", carTransmission) }

        let error = 0;
        if (requested) {
            if (!validateEmail(email)) {
                setErrorEmail("Inserire una mail valida")
                error = 1;
            } else {
                formdata.append("email", email)
            }

            if (!termAccepted) {
                setErrorTerm("Bisogna accettare i termini del servizio");
                error = 1;
            }
            formdata.append("phone", phone)
        }
        formdata.append("ip", ip);

        if (error == 0) {
            setShowModalSaveSearch(false)
            ajaxPost("saveSearch.php", formdata).then((response) => {
                if (requested) {
                    setShowModalSaveSearchConfirmed(true);
                }
            })
        }
    }

    return (
        <Layout>
            <section>
                <div className="container">
                    <div className="row justify-content-between align-items-center" style={isMobile ? { marginBottom: -80 } : null}>
                        <div className="d-block d-md-none col-12">
                            <div className={styles.topbarFilterMobile}>
                                <input
                                    className="w-100"
                                    placeholder="Cerca"
                                    onBlur={(val) => { setCarName(val.target.value); }}
                                    onKeyUp={(val) => { if (val.key == "Enter") { setCarName(val.target.value) } }}
                                />
                                <img src="./site_images/icon/Icon_filters.png" width={32} height={28} onClick={() => mobileFilter.current()} />
                            </div>
                        </div>

                        <div className="col" style={{ display: "flex", alignItems: "center" }} >
                            <h2 className="mb-0">{count} {translate("RisRicercaTitoloA")} <span className={"d-none d-md-inline"}>{translate("RisRicercaTitoloB")}</span></h2>
                            <div className="d-none d-md-block">
                                <Button
                                    icon={["far", "star"]}
                                    iconSize={15}
                                    style={{ padding: 8, marginTop: 8, backgroundColor: "#990047", color: "white", fontSize: 15, fontWeight: 500, marginLeft: 16, borderWidth: 0 }}
                                    label={"Salva la tua ricerca"}
                                    variation={"primary"}
                                    onClick={() => setShowModalSaveSearch(true)}
                                />
                            </div>
                        </div>

                        <div className="col-auto">
                            <Dropdown className="dropdown-order">
                                <Dropdown.Toggle>
                                    {translate("RisRicercaOrdina")} <b>{orderBy}</b>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { setOrderBy("Ultimi Arrivi"); setCurrentPage(0); }}>{translate("RisRicercaOrdina01")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Prezzo Crescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina02")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Prezzo Decrescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina03")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Anno Crescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina04")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Anno Decrescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina05")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Chilometraggio Crescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina06")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setOrderBy("Chilometraggio Decrescente"); setCurrentPage(0); }}>{translate("RisRicercaOrdina07")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <div className="d-block d-md-none">
                            <Button
                                icon={["far", "star"]}
                                iconSize={15}
                                style={{ padding: 8, backgroundColor: "#990047", color: "white", fontSize: 15, fontWeight: 500, borderWidth: 0, marginBottom: 8, height: 40 }}
                                label={"Salva la tua ricerca"}
                                variation={"primary"}
                                onClick={() => setShowModalSaveSearch(true)}
                            />
                        </div>
                        <div className="d-block d-md-none col-12">
                            {renderMobileFilterButton()}
                        </div>

                    </div>
                </div>
            </section>

            <FilterMobile renderFilters={() => renderFilters()} ref={mobileFilter} />

            <section>
                <div className="container">
                    <div className="row" style={isMobile ? { marginBottom: -50 } : null}>
                        <div className="d-none d-lg-block col-4">
                            {renderFilters()}
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="d-none d-md-block col-12">
                                    {renderMobileFilterButton()}
                                </div>

                                {!pageLoaded ?
                                    <div className="col-12 d-flex">
                                        <span class="loader"></span>
                                    </div>
                                    : null
                                }

                                {carList.map((el, i) => {
                                    return (
                                        <div className="col-12 col-sm-6 col-xl-4 mb-4" key={"car-preview-" + i} >
                                            <CarPreview data={el} bookmarkFlag={checkBookmark(el.VclId)} />
                                        </div>
                                    )
                                })}
                            </div>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            </section>

            <div
                className={styles.contactBackDrop}
                style={{
                    backgroundColor: showModalSaveSearch ? "#002A59a0" : "#00000000",
                    display: showModalSaveSearch ? "block" : "none",
                    pointerEvents: showModalSaveSearch ? "all" : "none",
                }}
                onClick={() => { setShowModalSaveSearch(false) }}
            >
                <div className={showModalSaveSearch ? "" : "d-none"} style={{ marginTop: 160 }}>
                    <div className={styles.modalCard} onClick={(e) => e.stopPropagation()}>
                        <img src="./site_images/icon/icon-close.png" className={styles.closeModalButton} onClick={() => setShowModalSaveSearch(false)} />
                        <h2>Salva la tua ricerca</h2>
                        <p>Rimani aggiornato su annunci con le caratteristiche di queste ricerca.</p>
                        {errorEmail ?
                            <span className={styles.errorLabel}>{errorEmail}</span>
                            : null
                        }
                        <input className="w-100 mb-3" placeholder={"*Inserisci la tua e-mail"} onChange={(val) => { setEmail(val.target.value); setErrorEmail("") }} />
                        {errorPhone ?
                            <span className={styles.errorLabel}>{errorPhone}</span>
                            : null
                        }
                        <input className="w-100" placeholder={"Inserisci il tuo numero di telefono"} onChange={(val) => { setPhone(val.target.value); setErrorPhone("") }} />
                        {errorTerm ?
                            <span className={styles.errorLabel}> {errorTerm}</span>
                            : null
                        }
                        <div className={"col-12 mb-2 d-flex"} style={{ alignItems: "center" }}>
                            <input required="required" type="checkbox" id="contact-form-terms" value={"1"} onChange={(val) => { setTermAccepted(val.target.checked); setErrorTerm("") }} />
                            <label htmlFor="contact-form-terms" style={{ fontSize: 12, color: "#002A59" }}>
                                Accosento i termini del servizio e della <a href="/privacy-policy">Privacy Policy</a>
                            </label>
                        </div>

                        <Button
                            variation="tertiary"
                            label="Salva ricerca"
                            style={{ height: 40, margin: "auto" }}
                            onClick={() => saveResearch(true)}
                        />
                    </div>
                </div>
            </div>

            <div
                className={styles.contactBackDrop}
                style={{
                    backgroundColor: showModalSaveSearchConfirmed ? "#002A59a0" : "#00000000",
                    display: showModalSaveSearchConfirmed ? "block" : "none",
                    pointerEvents: showModalSaveSearchConfirmed ? "all" : "none",
                }}
                onClick={() => { setShowModalSaveSearchConfirmed(false) }}
            >
                <div className={showModalSaveSearchConfirmed ? "" : "d-none"} style={{ marginTop: 160 }}>
                    <div className={styles.modalCard} onClick={(e) => e.stopPropagation()}>
                        <img src="./site_images/icon/icon-close.png" className={styles.closeModalButton} onClick={() => setShowModalSaveSearchConfirmed(false)} />
                        <h2>Ricerca Salvata</h2>
                        <p>Verrai aggiornato non appena un veicolo con le caratteristiche richieste verrà caricato sul portale</p>
                        <img src="./site_images/icon/icon_check_big.png"  width={100}/>
                    </div>
                </div>
            </div>

        </Layout>
    );
}
