import { useState, useEffect, useRef } from "react";
import React from 'react';

import Button from '../button/button';
import styles from "./style.module.scss";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {isMobile} from 'react-device-detect';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); 
    return windowSize;
}

let firstClientX, clientX;

const preventTouch = e => {
const minValue = 5; // threshold

clientX = e.touches[0].clientX - firstClientX;

// Vertical scrolling does not work when you start swiping horizontally.
if (Math.abs(clientX) > minValue) {
  e.preventDefault();
  e.returnValue = false;

  return false;
 }
 };

 const touchStart = e => {
  firstClientX = e.touches[0].clientX;
 };



export default function MyCarousel(props) {

    const size = useWindowSize();

    const [showArrow, setShowArrow] = useState(props.showArrow)
    const [showDots, setShowDots] = useState(false)
    const [centerMode, setCenterMode] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isMoving, setIsMoving] = useState(false)
    const [type, setType]=useState("")

    let containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("touchstart", touchStart);
            containerRef.current.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("touchstart", touchStart);
                containerRef.current.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };
    });


    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: props.desktop ? props.desktop : 3

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: props.tablet ? props.tablet : 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: props.mobile ? props.mobile : 1,
            partialVisibilityGutter:20
        }
    };

    if (size.width <= responsive.desktop.breakpoint.max && size.width > responsive.desktop.breakpoint.min && type != "Desktop") {
        setType("Desktop")
        setShowArrow(props.showArrow)
        setShowDots(props.showDots)
        setCenterMode(props.centerMode)
     }

    if (size.width <= responsive.tablet.breakpoint.max && size.width > responsive.tablet.breakpoint.min && type != "Tablet") {
        setType("Tablet")
        setShowArrow(props.showArrowTablet)
        setShowDots(props.showDotsTablet)
        setCenterMode(props.centerModeTablet)
    }

    if (size.width <= responsive.mobile.breakpoint.max && size.width > responsive.mobile.breakpoint.min && type!="Mobile") {
        setShowArrow(props.showArrowMobile)
        setShowDots(props.showDotsMobile)
        setCenterMode(props.centerModeMobile)
        setType("Mobile")
    }

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;

        let arrowPosition = styles.buttonGroup

        switch (props.arrowPosition) {
            case "bottomRight": arrowPosition = styles.buttonGroupBottomRight; break;
            case "bottomCenter": arrowPosition = styles.buttonGroupBottomCenter; break;
        }

        return (
            <div className={arrowPosition} >
                <div className={styles.leftArrowContainer}>
                    <Button
                        onClick={() => previous()}
                        icon={"arrow-left"}
                    />
                </div>
                <div className={styles.rightArrowContainer}>
                    <Button
                        onClick={() => next()}
                        icon={"arrow-right"}
                    />
                </div>
            </div>
        );
    };

    const renderChildren = () => {
        return React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
                isMoving: isMoving,
                insideCarousel: true,
            });
        });
    };



    return (
        <div ref={containerRef} className={props.showPartial ?styles.carouselContainerCenter:styles.carouselContainer}>
            <Carousel
                responsive={responsive}
                arrows={props.showDefaultArrow?true:false}
                infinite={true}
                showDots={showDots}
                renderDotsOutside={true}
                renderButtonGroupOutside={true}
                customButtonGroup={showArrow ? <ButtonGroup /> : null}
                style={{ marginLeft: 50 }}
                containerClass={props.containerClass ? props.containerClass : ""}
                itemClass={props.showPartial ? "item-center p-1" : "p-1"}
                beforeChange={() => setIsMoving(true)}
                afterChange={(previousSlide, { currentSlide, onMove }) => { setIsMoving(false); setCurrentSlide(currentSlide) }}
                className={props.showPartial ? "sliderCenterMode" : ""}
            >
                {/*props.children*/}
                {renderChildren()}
            </Carousel>
        </div>
    );
}
