import React, {useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as brandIcons from '@fortawesome/free-brands-svg-icons';

import Home from './routes/home/home';
import About from './routes/about/about';
//import Services from './routes/services/services';
//import Assistance from './routes/assistance/assitance';
import Brands from './routes/brands/brands';
import Contacts from './routes/contacts/contacts';
import Vehicle from './routes/vehicle/vehicle';
import Porsche from './routes/porsche/porsche';
import Faq from './routes/faq/faq';
import Listing from './routes/listing/listing';
import Bookmark from './routes/bookmark/bookmark';

import Commercial from './routes/pages/commercial';
import LongTerm from './routes/pages/longTerm';
import California from './routes/pages/california';
import Service from './routes/pages/service';
import CarBody from './routes/pages/carbody';

import Terms from './routes/terms/terms';
import Cookie from './routes/terms/cookie';
import Privacy from './routes/terms/privacy';

import ThankYou from './routes/thank-you/thank-you';
import Unsubscribe from './routes/unsubscribe/unsubscribe';


import { BASENAME } from "./constant";

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import { ajaxGet } from './helper/ajax';

const solidIconList = Object
    .keys(solidIcons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => solidIcons[icon])

const regularIconList = Object
    .keys(regularIcons)
    .filter(key => key !== "far" && key !== "prefix")
    .map(icon => regularIcons[icon])

const brandIconList = Object
    .keys(brandIcons)
    .filter(key => key !== "fab" && key !== "prefix")
    .map(icon => brandIcons[icon])

library.add(...brandIconList)
library.add(...solidIconList)
library.add(...regularIconList)


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/chi-siamo",
        element: <About />,
    },
    {
        path: "/noleggio-lungo-termine",
        element: <LongTerm />,
    },
    {
        path: "/noleggio-veicoli-commerciali",
        element: <Commercial />,
    },
    {
        path: "/noleggio-california",
        element: <California />,
    },
    {
        path: "/service",
        element: <Service />,
    },
    {
        path: "/carrozzeria",
        element: <CarBody />,
    },
    {
        path: "/marchi",
        element: <Brands />,
    },
    {
        path: "/contatti",
        element: <Contacts />,
    },
    {
        path: "/faq",
        element: <Faq />,
    },
    {
        path: "/privacy-policy",
        element: <Privacy />,
    },
    {
        path: "/cookie-policy",
        element: <Cookie />,
    },
    {
        path: "/termini-e-condizioni",
        element: <Terms />,
    },
    {
        path: "/porsche",
        element: <Porsche />,
    },
    {
        path: "/preferiti",
        element: <Bookmark />,
    },
    {
        path: "/veicoli",
        element: <Listing />,
    },
    {
        path: "/veicolo/:id",
        element: <Vehicle />,
    },
    {
        path: "/thank-you",
        element: <ThankYou />,
    },
    {
        path: "/unsubscribe",
        element: <Unsubscribe />,
    },
   
], { basename: BASENAME });


const root = ReactDOM.createRoot(document.getElementById('root'));


const  downloadText = () => {
    ajaxGet("getTranslation.php").then((response) => {
        if (response.code == "100") {
            //console.log(response);
            global.text = response.message;
            root.render(
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            );

        }
    });
}
downloadText()
