import React, { useRef} from 'react';
import styles from "./style.module.scss";
import Footer from "../../components/footer/footer";
import Header from '../../components/header/header';
import ContactForm from '../../components/contactForm/contactForm';
// import CookieConsentComponent from '../../components/cookieConsent/cookieConsent'


export default function Layout(props) {
    const myRef = useRef();

    return (
        <div>
            {/* <CookieConsentComponent /> */}
            <Header />
            <div className={styles.mainContainer}>
                {props.children}
            </div>
            {props.removeContactForm ? null :
                <ContactForm ref={myRef}/>
            }
            <Footer />
        </div>
    );
}

