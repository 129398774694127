import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";


export default function Cookie() {


    return (
        <Layout>
            <Hero
                image={"bg_hero_brands.png"}
                title={"Cookie policy"}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Cookie policy", link: null }
                ]}

            />
            <section>
                <div className="container">
                    <a href="https://www.iubenda.com/privacy-policy/29122716/cookie-policy" class="iubenda-nostyle iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>
                </div>
            </section>

        </Layout>


    );
}

