import React, { useState , useEffect} from 'react';
import styles from "./style.module.scss";
import headerLink from "../header/headerLink";
import Button from '../button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASENAME } from "../../constant"
import { SMTP } from "../../constant"
import { isMobile } from 'react-device-detect';
import footerLogo from "../../img/mini_logo.png"
import { useNavigate } from "react-router-dom";
import { translate, translateValue } from '../../helper/functions';

export default function Footer(props) {
    const navigate = useNavigate();
    
    // per il controllo della larghezza dello schermo
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Call when page is rendered, it set windows size fore the used device
    useEffect(() => {
        // Function to update windowWidth when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function checkIpad(screenWidth) {
        if (screenWidth > 567)
            return true
        return false
    }

    return (
        <div className={styles.footerContainer} style={isMobile ? { marginTop:-56 } : { marginTop:-15 }}>
            <div className={"container py-5"}>
                <div className={"row"}>
                    <div className={"col-12 col-lg-1 text-center text-lg-start d-flex align-items-start justify-content-center"} style={isMobile ? {marginTop: -8, marginBottom: 20} : {marginBottom: 30}}>
                        <img src={translateValue("FooterLogo")} alt="logo" className={styles.logo} />
                    </div>
                    <div className={"col-12 col-lg-4 d-flex flex-column text-center text-lg-start pt-5 pt-sm-0"}>
                        <div className={styles.infoTextBig}>
                            {/* <FontAwesomeIcon icon="phone" /> */}
                            <img src="../site_images/svg/Phone.svg" width={22} height={22} fill="white" style={{ textAlign: 'right', marginRight: 10 }} />
                            <a className={"unstyleLink"} href={"tel:+39" + global.text["FooterTel"].Testo} target="_blank">{global.text["FooterTel"].Testo}</a>
                        </div>
                        <div className={styles.infoTextBig}>
                            <FontAwesomeIcon icon={["far", "envelope"]} />
                            <span onClick={() => window.open('mailto:' +  global.text["FooterMail"].Testo )} style={{cursor: "pointer"}}>
                                {global.text["FooterMail"].Testo}
                            </span>
                        </div>
                        { isMobile ? (checkIpad(windowWidth) ? 
                            (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; {global.text["FooterSede1"].Testo}</span>
                                    </a>
                                </div>
                            ) : (
                                <>
                                    <div className='mb-3'></div>
                                    <div className='row align-items-center justify-content-center text-center text-md-start'>
                                        <div className='col-auto d-md-none'>
                                            <img src="../site_images/svg/Map_marker.svg" width={24} height={24} fill="white" style={{ textAlign: 'right', marginRight: -120 }} />
                                        </div>

                                        <div className='col'>
                                            <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                                <span style={{ fontSize: 15, display: 'block' }}>{global.text["FooterSede1"].Testo}</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )) : (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{ marginLeft: -3 }} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; {global.text["FooterSede1"].Testo}</span>
                                    </a>
                                </div>
                            )
                        }
                        { isMobile ? (checkIpad(windowWidth) ? 
                            (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; {global.text["FooterSede2"].Testo}</span>
                                    </a>

                                </div>
                            ) : (
                                <>
                                    <div className='mb-3'></div>
                                    <div className='row align-items-center justify-content-center text-center text-md-start'>
                                        <div className='col-auto d-md-none'>
                                            <img src="../site_images/svg/Map_marker.svg" width={24} height={24} fill="white" style={{ textAlign: 'right', marginRight: -190 }} />
                                        </div>

                                        <div className='col'>
                                            <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                                <span style={{ fontSize: 15, display: 'block' }}> {global.text["FooterSede2"].Testo}</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )) : (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp;  {global.text["FooterSede2"].Testo}</span>
                                    </a>
                                </div>
                            )
                        }
                        <div className='mb-3'></div>
                        
                    </div>

                    <div className="col-1"/>
                    <div className="col-6">
                        <div className="row">
                        <div className={"col-12 col-lg-3 flex-column d-none d-lg-flex"}>
                                <div className={styles.footerTitle}>{global.text["FooterColonna1Item"].Testo}</div>
                                <a href={BASENAME + "veicoli"} className={styles.footerLink}>{global.text["FooterColonna1-01"].Testo}</a>
                                <a href={BASENAME + "veicoli"} className={styles.footerLink}>{global.text["FooterColonna1-02"].Testo}</a>
                                <a href={BASENAME + "veicoli"} className={styles.footerLink}>{global.text["FooterColonna1-03"].Testo}</a>
                                <a href={BASENAME + "veicoli"} className={styles.footerLink}>{global.text["FooterColonna1-04"].Testo}</a>
                            </div>

                        <div className={"col-12 col-lg-3 flex-column d-none d-lg-flex"}>
                                <div className={styles.footerTitle}>{global.text["FooterColonna2Item"].Testo}</div>
                                <a href={BASENAME + "noleggio-california"} className={styles.footerLink}>{global.text["FooterColonna2-01"].Testo}</a>
                                <a href={BASENAME + "noleggio-veicoli-commerciali"} className={styles.footerLink}>{global.text["FooterColonna2-02"].Testo}</a>
                                <a href={BASENAME + "noleggio-lungo-termine"} className={styles.footerLink}>{global.text["FooterColonna2-03"].Testo}</a>
                        </div>

                        <div className={"col-12 col-lg-3 flex-column d-none d-lg-flex"}>
                                <div className={styles.footerTitle}>{global.text["FooterColonna3Item"].Testo}</div>
                                <a href={BASENAME + "service"} className={styles.footerLink}>{global.text["FooterColonna3-01"].Testo}</a>
                                <a href={BASENAME + "carrozzeria"} className={styles.footerLink}>{global.text["FooterColonna3-02"].Testo}</a>
                        </div>

                        <div className={"col-12 col-lg-3 flex-column d-none d-lg-flex"}>
                                <div className={styles.footerTitle}>{global.text["FooterColonna4Item"].Testo}</div>
                                <a href={BASENAME + "contatti"} className={styles.footerLink}>{global.text["FooterColonna4-01"].Testo}</a>
                                <a href={BASENAME + "chi-siamo"} className={styles.footerLink}>{global.text["FooterColonna4-02"].Testo}</a>
                            {/*<a href={BASENAME + "carrozzeria"} className={styles.footerLink}>Lavora con noi</a>*/}
                                <a href={BASENAME + "termini-e-condizioni"} className={styles.footerLink}>{global.text["FooterColonna4-03"].Testo}</a>
                                <a href={BASENAME + "privacy-policy"} className={styles.footerLink}>{global.text["FooterColonna4-04"].Testo}</a>
                                <a href={BASENAME + "cookie-policy"} className={styles.footerLink}>{global.text["FooterColonna4-05"].Testo}</a>
                        </div>
                        </div>
                    </div>

                </div>

                <div className={styles.socialContainerMobile}>
                    {headerLink.socials.map((social, index) => {
                        return (
                            <Button key={"social-footer-mobile-" + index} icon={social.icon} onClick={() => window.open(social.link, '_blank')} variation={"secondary"} />
                        )
                    })}
                </div>

                <div className={styles.divider} />

                <div className={"col-12"}>

                    <div className={styles.bottomContainer} style={isMobile ? {marginBottom:-12} : null}>
                        <div className={styles.copyrightContainer}>
                            {global.text["FooterCopyright"].Testo}
                        </div>
                        <div className={styles.socialContainer}>
                            {headerLink.socials.map((social, index) => {
                                return (
                                    <Button key={"social-footer-" + index} icon={social.icon} onClick={() => window.open(social.link, '_blank')} variation={"secondary"} style={{border:"2px solid #2F527A"}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

