import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import { translate, translateValue } from '../../helper/functions';



export default function LongTerm() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>{translate("NoleggioVclLungoTerTitolo")}</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: translate("NoleggioVclLungoTerPath01"), link: BASENAME },
                        { label: translate("NoleggioVclLungoTerPath02"), link: null },
                        { label: translate("NoleggioVclLungoTerPath03"), link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src={translateValue("NoleggioVclLungoTer01Img01Mobile")} className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pe-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo01") }} />


                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2 dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo04") }} />
                                        <p dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo05") }} />
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2 dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo06") }} />
                                        <p dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo07") }} />
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2 dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo08") }} />
                                        <p dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo09") }} />
                                    </div>
                                </div>

                                <br />
                                <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: translate("NoleggioVclLungoTer01Testo10") }} />


                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={translate("NoleggioVclLungoTerTel")}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={() => window.open('tel:' + translate("NoleggioVclLungoTerTel"))}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width:60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 d-none d-lg-block">
                            <img src={translateValue("NoleggioVclLungoTer01Img01")} className={styles.image} />
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    );
}

