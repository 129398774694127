import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";
import { ajaxGet, ajaxPost } from "../../helper/ajax";
import Hero from '../../components/hero/hero';

import CarPreview from "../../components/carPreview/carPreview";
import MyCarousel from '../../components/carousel/carousel';
import { BASENAME, COOKIE_BOOKMARKS } from "../../constant";

import { getCookieForBookmark } from "../../helper/functions";

export default function Bookmark() {
    const [carList, setCarList] = useState([]);
    const [carListFeatured, setCarListFeatured] = useState([]);

    function getOnlyBookmarked(carId) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+carId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === true) {
            console.log(`Try to check for → ${carId} as Bookmarked`);
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        let formdata = new FormData();
        formdata.append("limit", 1000);
        ajaxPost("getVclList.php", formdata).then((response) => {
            if (response.code == "100") {
                let tempCarList = []
                // filtra l'array auto prima di salvarlo in carList
                response.message.car.map((el, i) => {
                    if (getOnlyBookmarked(el.VclId)) { 
                        tempCarList.push(el);
                    }
                })
                setCarList(tempCarList);
            }
        });

        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                setCarListFeatured(response.message)
            }
        });
    }, [])

    return (
        <Layout>
            <Hero onlyBreadcrumb={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Preferiti", link: null }
                ]}

            />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className={`${styles.title} text-left`}>Le tue auto preferite</h2>
                            <div className={`${styles.text} text-left`}>{carList.length} auto</div>
                        </div>
                    </div>
                    <div className="row">
                        {carList.map((el, i) => 
                            (<div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4" key={"car-preview-" + i}>
                                <CarPreview data={el} />
                            </div>)
                        )}
                    </div>
                </div>
                <section style={{ maxWidth: 1545, margin: 'auto' }}>
                    <h2 className="text-center mt-5 mb-4">Potresti anche essere interessato a questi veicoli</h2>
                    <MyCarousel
                        showArrow={true}
                        containerClass="car-slider"
                    >
                        {carListFeatured.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-" + i} /> 
                        })}
                    </MyCarousel>
                </section>
            </section>
        </Layout>
    );
}

